import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { config } from 'config'
import { motion } from 'framer-motion'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useState } from 'react'

interface CopyCSVLinkModalProps {
  isOpen: boolean
  onClose: () => void
  segmentationSetName: string
  segmentationSetId: string
}

export const getProductSetCSVLink = (
  merchantId: string,
  segmentationId: string,
) => {
  const domain =
    config.APP_ENV === 'production'
      ? 'download.dema.ai'
      : 'download.staging.dema.ai'

  return `https://${domain}/product-set/${merchantId}/${segmentationId}.csv`
}

export const CopyCSVLinkModal: React.FC<CopyCSVLinkModalProps> = ({
  isOpen,
  onClose,
  segmentationSetName,
  segmentationSetId,
}) => {
  const { merchantId } = useMerchantInfo()
  const [showCopyButton, setShowCopyButton] = useState(true)
  const csvLink = getProductSetCSVLink(merchantId, segmentationSetId)
  const [trackEvent] = useTrackEvent()

  const copyTextHandler = () => {
    if (showCopyButton === false) return

    trackEvent({
      eventName: 'Segmentations Product Set CSV Link Copied',
      eventProperties: {
        name: segmentationSetName,
        id: segmentationSetId,
        fromModal: true,
      },
    })

    setShowCopyButton(false)
    navigator.clipboard.writeText(csvLink)

    setTimeout(() => {
      setShowCopyButton(true)
    }, 1200)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New product set added</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            The new product set{' '}
            <Typography
              as="span"
              fontSize="inherit"
              color="gray.900"
              fontWeight={700}
            >
              {segmentationSetName}
            </Typography>{' '}
            has been added. The product set will be exported daily to an
            externally stored CSV file. Use the link to the CSV file below to
            connect the product set to your ad platforms.
          </Box>

          <Flex mt={6} p={2} bg="gray.200" gap={2}>
            <Typography
              fontFamily="Fira Code"
              fontStyle="normal"
              fontSize="xs"
              color="gray.900"
              fontWeight={400}
              lineHeight={5}
            >
              {csvLink}
            </Typography>

            <Flex
              flexShrink={0}
              justifyContent="center"
              alignItems="center"
              w={6}
              h={6}
              cursor="pointer"
              position="relative"
              onClick={copyTextHandler}
              className="absolute right-1 top-1/2 flex h-8 w-8 -translate-y-1/2 items-center justify-center"
            >
              <Box
                as={motion.div}
                position="absolute"
                w={4}
                h={4}
                variants={{
                  show: { scale: 1, opacity: 1 },
                  hide: { scale: 0.5, opacity: 0 },
                }}
                animate={showCopyButton ? 'show' : 'hide'}
              >
                <Icon name="Copy2Icon" size="small" />
              </Box>

              <Box
                as={motion.div}
                position="absolute"
                w={4}
                h={4}
                variants={{
                  show: { scale: 1, opacity: 1 },
                  hide: { scale: 0.5, opacity: 0 },
                }}
                animate={showCopyButton ? 'hide' : 'show'}
              >
                <Icon name="CheckIcon" size="small" />
              </Box>
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
