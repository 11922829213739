import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { Dropdown } from 'components/Dropdown'
import Input from 'components/Input/Input'

import { ColorPicker } from 'components/Pickers/ColorPicker'
import { GenericPickerModal } from 'components/Pickers/GenericPickerModal'
import { IconPicker } from 'components/Pickers/IconPicker'
import { Typography } from 'components/Typography'

import {
  type SegmentationSetFieldsFragment,
  type CreateSegmentationSetInput,
} from 'generated/graphql/graphql'
import {
  useDefaultDisclosure,
  type DefaultDisclosure,
} from 'hooks/useDefaultDisclosure'
import { type FC, useState, type ReactElement, useRef } from 'react'
import {
  SEGMENTATION_SET_COLORS,
  PRODUCT_SET_DEFAULT_DATE_RANGE,
  PRODUCT_SET_LABEL_COLUMN_NAME,
  PRODUCT_SET_LABEL_COLUMN_NAMES,
  SEGMENTATION_SET_ICON_NAMES,
} from '../../../consts'

export type UpdatedSegmentationSet = CreateSegmentationSetInput & {
  id?: string
}

const defaultSegmentationSet: UpdatedSegmentationSet = {
  iconColor: SEGMENTATION_SET_COLORS[0],
  name: '',
  iconName: SEGMENTATION_SET_ICON_NAMES[0],
  dynamicDate: PRODUCT_SET_DEFAULT_DATE_RANGE,
  labelColumnName: PRODUCT_SET_LABEL_COLUMN_NAME,
}

type SegmentationSetPickerProps = {
  currentSegmentationSet: SegmentationSetFieldsFragment | undefined
  modalButton: ReactElement
  isEditMode?: boolean
  onApply: (segmentationSet: UpdatedSegmentationSet) => void
  disclosure?: DefaultDisclosure
  isLoading?: boolean
  closeOnApply?: boolean
}

export const SegmentationSetPicker: FC<SegmentationSetPickerProps> = ({
  currentSegmentationSet,
  modalButton,
  isEditMode,
  onApply,
  disclosure,
  isLoading,
  closeOnApply = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [segmentationSet, setSegmentationSet] =
    useState<UpdatedSegmentationSet>(
      currentSegmentationSet ?? defaultSegmentationSet,
    )
  const { isOpen, onOpen, onClose } = useDefaultDisclosure(disclosure)

  return (
    <GenericPickerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      modalButton={modalButton}
      setToDefaultState={() => {
        if (!currentSegmentationSet) {
          setSegmentationSet(defaultSegmentationSet)
        }
      }}
      initialFocusRef={inputRef}
    >
      <ModalHeader>
        {isEditMode ? 'Edit product set' : 'Add product set'}
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Typography mb={4}>
          Choose a color and an icon for the product set.
        </Typography>

        <Box mb={6}>
          <ColorPicker
            colors={SEGMENTATION_SET_COLORS}
            selectedColor={segmentationSet.iconColor}
            setSelectedColor={(iconColor) =>
              setSegmentationSet((segmentationSet) => ({
                ...segmentationSet,
                iconColor,
              }))
            }
          />
        </Box>

        <Box mb={8}>
          <IconPicker
            icons={SEGMENTATION_SET_ICON_NAMES}
            iconColor={segmentationSet.iconColor}
            selectedIcon={segmentationSet.iconName}
            setSelectedIcon={(iconName) =>
              setSegmentationSet((segmentationSet) => ({
                ...segmentationSet,
                iconName,
              }))
            }
          />
        </Box>
        <Box mb={8}>
          <Input
            ref={inputRef}
            label="Product set name"
            title="Product set name"
            placeholder=""
            value={segmentationSet.name}
            maxLength={50}
            onChange={(e) =>
              setSegmentationSet((segmentationSet) => ({
                ...segmentationSet,
                name: e.target.value,
              }))
            }
          />
        </Box>

        <Dropdown
          options={PRODUCT_SET_LABEL_COLUMN_NAMES}
          value={segmentationSet.labelColumnName}
          label="Custom label name"
          callback={(labelColumnName) =>
            setSegmentationSet((segmentationSet) => ({
              ...segmentationSet,
              labelColumnName: labelColumnName.id,
            }))
          }
        />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={
            !segmentationSet.name ||
            !segmentationSet.iconColor ||
            !segmentationSet.iconName
          }
          variant="solid"
          type="submit"
          colorScheme="primary"
          isLoading={isLoading}
          onClick={() => {
            onApply(segmentationSet)
            if (closeOnApply) {
              onClose()
            }
          }}
        >
          {isEditMode ? 'Save' : 'Add product set'}
        </Button>
      </ModalFooter>
    </GenericPickerModal>
  )
}
