import {
  getCompareDatePreset,
  getDatePreset,
  type COMPARE_DYNAMIC_DATE_ID,
  type DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import { FilterOperator } from 'components/Filters/types'
import { endOfDay, startOfDay, sub } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { type ChartSerie } from 'graphql/reports/types'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import { DEFAULT_ATTRIBUTION_ID } from 'graphql/statistics/useAttributionModels'
import {
  type Dimension,
  type NormalizedDimensions,
} from 'graphql/statistics/useDimensions'
import {
  getMetricKeyWithoutAttribution,
  type Metric,
  type NormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { pick } from 'lodash-es'
import { getStore } from 'shared/store'
import {
  CHART_TYPE_ID,
  chartTypes,
  multiMetricChartTypes,
  type ChartTypeId,
} from 'utils/chart/chartTypes'
import { compareColor, dayDimension, isTimeUnit } from 'utils/chart/constants'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'

export const getDefaultDateRange = (
  urlDateRange: (string | number | null)[] | null | undefined,
  timezone: string | undefined,
  compare = false,
): DateRange | null | undefined => {
  if (!urlDateRange) {
    return urlDateRange
  }
  const now = new Date()
  const [first, second] = urlDateRange

  const startDate =
    first && timezone
      ? toZonedTime(Number(first), timezone)
      : compare
        ? null
        : sub(startOfDay(now), { weeks: 4 })
  const endDate =
    second && timezone
      ? toZonedTime(Number(second), timezone)
      : compare
        ? null
        : endOfDay(now)

  return [startDate, endDate]
}

export const getPresetFromUrlDynamicDate = (
  urlDynamicDate: string | null | undefined,
) => {
  return urlDynamicDate
    ? getDatePreset(urlDynamicDate as DYNAMIC_DATE_ID)
    : undefined
}

export const getPresetFromUrlCompareDynamicDate = (
  urlDynamicDate: string | null | undefined,
  dateRange: DateRange,
) =>
  urlDynamicDate
    ? getCompareDatePreset(
        urlDynamicDate as COMPARE_DYNAMIC_DATE_ID,
        dateRange[0],
        dateRange[1],
      )
    : undefined

const isValidXAxis = (
  xAxis: string,
  isScatterPlot: boolean,
  dimensions: string[],
  metrics: string[],
) => {
  return (
    (!isScatterPlot &&
      (dimensions.some((dim) => dim === xAxis) || isTimeUnit(xAxis))) ||
    (isScatterPlot && metrics.some((metric) => metric === xAxis))
  )
}
const isValidYAxis = (yAxis: string, metrics: string[]) => {
  return metrics.some((metric) => metric === yAxis)
}

const isValidGColor = (
  gColor: string,
  dimensions: string[],
  xAxis: string,
  isCompare: boolean,
  isMultiMetric: boolean,
) => {
  return (
    (!isMultiMetric &&
      xAxis !== gColor &&
      dimensions.some((dim) => dim === gColor)) ||
    (gColor === compareColor.id && isCompare)
  )
}
const isValidGType = (gType: ChartTypeId) => {
  return !!chartTypes[gType]
}

export const getDefaultXAxis = (
  urlXAxis: string | null | undefined,
  isScatterPlot: boolean,
  dimensions: string[],
  metrics: string[],
) => {
  if (urlXAxis && isValidXAxis(urlXAxis, isScatterPlot, dimensions, metrics)) {
    return urlXAxis
  }
  if (isScatterPlot) {
    return metrics[0]
  }

  return dayDimension.id
}

export const getDefaultYAxis = (
  urlYAxis: string | null | undefined,
  metrics: string[],
) => {
  if (urlYAxis && isValidYAxis(urlYAxis, metrics)) return urlYAxis

  return metrics[0]
}

export const getDefaultSeries = (
  series: ChartSerie[] | undefined,
  metrics: string[],
) => {
  if ((!metrics || metrics.length === 0) && !series) return []

  const defaultSeries: ChartSerie[] = [
    {
      key: metrics[0],
      type: CHART_TYPE_ID.LINE,
    },
  ]

  if (!series) {
    return defaultSeries
  }

  let validSeries = series.filter(({ key }) => isValidYAxis(key, metrics))

  if (validSeries.length > 1) {
    // Default to line chart type if type is not allow for multi-metric charts
    validSeries = validSeries.map((serie) => {
      if (!multiMetricChartTypes.includes(serie.type)) {
        return { ...serie, type: CHART_TYPE_ID.LINE }
      }

      return serie
    })
  }

  return validSeries.length > 0 ? validSeries : defaultSeries
}

export const getDefaultGColor = (
  urlGColor: string | null | undefined,
  dimensions: string[],
  xAxis: string,
  isCompare: boolean,
  isMultiMetric: boolean,
) => {
  if (
    urlGColor &&
    isValidGColor(urlGColor, dimensions, xAxis, isCompare, isMultiMetric)
  ) {
    return urlGColor
  }

  // Default color to compare date when no valid color is used and user has not directly cleared the group
  if (urlGColor !== null && isTimeUnit(xAxis) && isCompare) {
    return compareColor.id
  }

  return null
}

export const getValidCompareDateRange = (
  dateRange: DateRange,
  compareDateRange: DateRange,
): DateRange => {
  const [, end] = dateRange
  const [compareStart, compareEnd] = compareDateRange
  let newCompareEnd = compareEnd

  if (compareStart === null || compareEnd === null) return compareDateRange

  // compare range has to be earlier or equal than start range
  if (end !== null && compareEnd > end) {
    newCompareEnd = new Date(end)
  }

  return [compareStart, newCompareEnd]
}

export const getDefaultGType = (urlGType: ChartTypeId | null | undefined) => {
  if (urlGType && isValidGType(urlGType)) return urlGType

  return 'line'
}

export const getActualMetrics = (
  metrics: string[],
  normalizedMetrics: NormalizedMetrics,
) => {
  const metricsSet = new Set(metrics)

  // We loop through normalizedMetrics to guarantee showing the metrics in the same order. Ids could have different order
  return Object.entries(normalizedMetrics)
    .filter(([key]) => metricsSet.has(key))
    .map(([key, value]) => {
      if (!!value.attributionVariants && !('attributionId' in value)) {
        return normalizedMetrics[`${key}:${DEFAULT_ATTRIBUTION_ID}`] ?? value
      }

      return value
    })
}

export const getActualDimensions = (
  dimensions: string[],
  normalizedDimensions: NormalizedDimensions,
) => {
  const dimensionsSet = new Set(dimensions)

  // We loop through normalizedDimensions to guarantee showing the dimensions in the same order. Ids could have different order
  return Object.entries(normalizedDimensions)
    .filter(([key]) => dimensionsSet.has(key))
    .map(([, value]) => value)
}

export const initializeMetricsAttribution = (
  selectedItems: Metric[],
): Metric[] => {
  return selectedItems.map((item) => {
    if (item.attributionVariants) {
      const attributionId =
        'attributionId' in item ? item.attributionId : DEFAULT_ATTRIBUTION_ID

      return {
        ...item,
        key: `${item.key}:${attributionId}`,
        attributionId,
        dependencies:
          item.attributionVariants.find(
            ({ modelId }) => modelId === attributionId,
          )?.dependencies ?? [],
      }
    }

    return { ...item }
  })
}

export const filterOperatorMappings: Record<FilterOperator, string> = {
  contains: 'contains',
  doesNotContain: 'does not contain',
  isAnyOf: 'is any of',
  isNoneOf: 'is none of',
  gt: 'is greater than',
  lt: 'is less than',
  between: 'is between',
  topPercent: 'is top %',
  bottomPercent: 'is bottom %',
} as const

export type FilterOperatorSingleValue = Extract<
  FilterOperator,
  FilterOperator.isAnyOf | FilterOperator.isNoneOf
>

export const isFilterOperatorSingleValue = (
  operator: FilterOperator,
): operator is FilterOperatorSingleValue => {
  return [FilterOperator.isAnyOf, FilterOperator.isNoneOf].includes(operator)
}

export const filterOperatorMappingsWithSingleValue: Record<
  FilterOperatorSingleValue,
  string
> = {
  isAnyOf: 'is',
  isNoneOf: 'is not',
} as const

export type FilterOperatorShortNameValue = Extract<
  FilterOperator,
  FilterOperator.topPercent | FilterOperator.bottomPercent
>

export const filterOperatorMappingsWithShortName: Record<
  FilterOperatorShortNameValue,
  string
> = {
  topPercent: 'is top',
  bottomPercent: 'is bottom',
}

export const isFilterOperatorPercent = (
  operator: FilterOperator,
): operator is FilterOperatorShortNameValue =>
  [FilterOperator.topPercent, FilterOperator.bottomPercent].includes(operator)

export const getFilterNumericValue = (
  value: string | undefined,
  format: MetricFormat = METRIC_FORMAT.INTEGER,
  filterOperator: FilterOperator,
) => {
  let numberValue = Number(value)

  if (
    format.toLowerCase().includes(METRIC_FORMAT.PERCENT) &&
    !isFilterOperatorPercent(filterOperator)
  ) {
    numberValue /= 100
  }

  // Cutoff at 4 decimal places to allow decimal percentages
  return numberValue.toFixed(4)
}

export const getMetricLabelWithAttribution = (
  metric?: Metric,
  attributionLabel?: string,
) => {
  if (!metric) {
    return ''
  }

  return `${metric.label}${attributionLabel ? ` - ${attributionLabel}` : ''}`
}

export const getValidDimensions = (
  dimensions: Dimension[],
  metrics: Metric[],
) => {
  const metricDependencies: string[] = metrics
    .flatMap((metric) => metric.dependencies)
    .filter((dependency): dependency is string => !!dependency)
  const uniqueMetricDependencies = Array.from(new Set(metricDependencies))
  const validDimensions: Dimension[] = []

  for (const dimension of dimensions) {
    const processors = new Set(dimension.processors)
    const hasAllDependencies = uniqueMetricDependencies.every((dependency) =>
      processors.has(dependency),
    )

    if (hasAllDependencies) {
      validDimensions.push(dimension)
    }
  }

  return validDimensions
}

export const isValidMetric = (metric: Metric, validMetricGroups: string[]) => {
  const allDependencies =
    metric?.dependencies.length > 0
      ? [metric.dependencies]
      : metric.attributionVariants?.map(({ dependencies }) => dependencies)

  return allDependencies?.some((dependencyArray) =>
    dependencyArray.every((dependency) =>
      validMetricGroups.includes(dependency),
    ),
  )
}

export const getUniqueMetrics = (metrics: Metric[]) => {
  const existingKeys = new Set()

  return metrics.reduce<Metric[]>((acc, metric) => {
    const key = getMetricKeyWithoutAttribution(metric)

    if (!existingKeys.has(key)) {
      existingKeys.add(key)
      acc.push(metric)
    }

    return acc
  }, [])
}

export const getValidMetrics = (
  metrics: string[],
  normalizedMetrics: NormalizedMetrics,
  dimensions: Dimension[],
) => {
  const actualMetrics = getActualMetrics(metrics, normalizedMetrics)
  const validMetricProcessors = getValidMetricProcessors(dimensions)

  return getValidMetricsFromProcessors(
    actualMetrics,
    normalizedMetrics,
    validMetricProcessors,
  )
}

export const getValidMetricsFromProcessors = (
  prevMetrics: Metric[],
  normalizedMetrics: NormalizedMetrics,
  validMetricGroups: string[],
) => {
  const filteredMetrics = prevMetrics
    .map<Metric>((item) => {
      return normalizedMetrics[item.key]
    })
    .filter((metric) => isValidMetric(metric, validMetricGroups))

  return filteredMetrics
}

export const getValidMetricProcessors = (
  dimensions: (Dimension | undefined)[],
): string[] => {
  const allMetricProcessors = Array.from(
    new Set(dimensions.flatMap((dim) => dim?.processors)),
  )

  return dimensions.reduce((acc, next) => {
    return acc.filter<string>(
      (processor): processor is string =>
        !!processor &&
        !!next?.processors &&
        next.processors.includes(processor),
    )
  }, allMetricProcessors as string[])
}

export const getAnalyticsConfigFromStore = () => {
  // Get the current analytics config values. We don't use hooks since we don't need to subscribe to the values. This saves unnecessary renders
  const currentAnalyticsConfig = getStore().get(analyticsConfigAtom)

  return pick(currentAnalyticsConfig, [
    'dimensions',
    'metrics',
    'dynamicDate',
    'endDate',
    'startDate',
    'compareDynamicDate',
    'compareEndDate',
    'compareStartDate',
    'compareUnit',
    'chart',
    'filters',
    'tableState',
  ])
}
