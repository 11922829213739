import { useQuery, type QueryHookOptions } from '@apollo/client'
import { graphql } from 'generated/graphql'
import type { Country, CountriesQuery, Exact } from 'generated/graphql/graphql'
import { useMemo } from 'react'

const COUNTRIES_QUERY = graphql(/* GraphQL */ `
  query Countries {
    countries {
      id
      name
    }
  }
`)

export type NormalizedCountries = Record<string, Country>

export const useCountries = (
  options?:
    | QueryHookOptions<CountriesQuery, Exact<{ [key: string]: never }>>
    | undefined,
) => {
  const query = useQuery(COUNTRIES_QUERY, options)

  const { countries, normalizedCountries } = useMemo(() => {
    const countries =
      query.data?.countries
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name)) ?? []

    const normalizedCountries: NormalizedCountries = Object.fromEntries(
      countries.map((country) => [country.id, country]),
    )

    return {
      countries,
      normalizedCountries,
    }
  }, [query.data])

  return { countries, normalizedCountries, query }
}
