import { type ColumnDef } from '@tanstack/react-table'
import { TAXONOMY_TYPE, TAXONOMY_NAME, MANUAL_INPUT_FORMAT } from '../../consts'
import {
  type TaxonomyCost,
  type ManualInputColumns,
  type TaxonomyType,
  type TaxonomyInput,
  type Matcher,
} from '../../types'
import { getEmptyDataRow, getFallbackValueByFormat } from './common'
type GetSubRows = {
  parentDefaultValue: TaxonomyCost
  matchers?: Matcher['matchers']
  groupName?: string
}

const splitOnSeparator = (val: string) => val.split(/[.,]/)
const testIfDecimalsAreZero = (val: string) => {
  const splitVal = splitOnSeparator(val)

  return /0/.test(splitVal[0]) && /^0+$/.test(splitVal[1])
}

const getUpdatedDefault = (values: Array<string | undefined>) =>
  values.map((value) => {
    if (value === undefined) {
      return getFallbackValueByFormat(MANUAL_INPUT_FORMAT.NUMBER_CURRENCY)
    }

    return testIfDecimalsAreZero(value) ? splitOnSeparator(value)[0] : value
  })

const getSubRows = ({
  parentDefaultValue,
  matchers,
  groupName,
}: GetSubRows): ManualInputColumns[] | undefined => {
  if (!matchers) return undefined

  return Object.entries(matchers).map(([key, value]) => {
    const { currency, perOrder, perItem, perShipment, percentage } =
      value.default || parentDefaultValue

    const [
      updatedPerOrder,
      updatedPerItem,
      updatedPerShipment,
      updatedPercentage,
    ] = getUpdatedDefault([perOrder, perItem, perShipment, percentage])

    return {
      currency,
      perOrder: updatedPerOrder,
      perItem: updatedPerItem,
      perShipment: updatedPerShipment,
      percentage: updatedPercentage,
      groupName,
      name: key,
      subRows: getSubRows({
        parentDefaultValue,
        matchers: value.matchers,
        groupName: value.nextCondition ?? undefined,
      }),
    }
  })
}

type AddDefault = {
  taxonomyName: string
  columns: ColumnDef<ManualInputColumns, unknown>[]
  currency?: string
  taxonomyType: TaxonomyType
}

const addDefaultEmptyRows = ({
  taxonomyName,
  columns,
  currency,
  taxonomyType,
}: AddDefault) => {
  const row = getEmptyDataRow(columns, currency)

  return [
    {
      ...row,
      name: taxonomyName,
      type: taxonomyType,
    },
  ]
}

const getTaxonomyName = (type: TaxonomyType) => {
  const taxonomyTypeKey = Object.entries(TAXONOMY_TYPE).find(
    ([, value]) => value === type,
  )?.[0]

  return taxonomyTypeKey && taxonomyTypeKey in TAXONOMY_NAME
    ? TAXONOMY_NAME[taxonomyTypeKey as keyof typeof TAXONOMY_NAME]
    : undefined
}

type Props = {
  taxonomy?: TaxonomyInput
  type: TaxonomyType
  columns: ColumnDef<ManualInputColumns, unknown>[]
  defaultCurrency?: string
}

export const mapTaxonomyToTableData = ({
  taxonomy,
  type,
  columns,
  defaultCurrency,
}: Props) => {
  const taxonomyName = getTaxonomyName(type) ?? ''

  if (!taxonomy?.rootMatcher) {
    return addDefaultEmptyRows({
      taxonomyName,
      columns,
      currency: defaultCurrency,
      taxonomyType: type,
    })
  }

  const {
    default: defaultValue,
    matchers,
    nextCondition,
  } = taxonomy.rootMatcher
  const { currency, perOrder, perItem, perShipment, percentage } = defaultValue
  const [
    updatedPerOrder,
    updatedPerItem,
    updatedPerShipment,
    updatedPercentage,
  ] = getUpdatedDefault([perOrder, perItem, perShipment, percentage])

  const primaryRow =
    getSubRows({
      parentDefaultValue: defaultValue,
      matchers,
      groupName: nextCondition ?? undefined,
    }) ?? []

  const data = [
    {
      name: taxonomyName,
      currency,
      perOrder: updatedPerOrder,
      perItem: updatedPerItem,
      perShipment: updatedPerShipment,
      percentage: updatedPercentage,
      subRows: primaryRow,
      type: taxonomy.type,
    },
  ]

  return data
}
