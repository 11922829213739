import { DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { type IconName } from 'components/Icon/Icon'
import { colorTheme } from 'ui/theme/colors'

export const PRODUCT_SET_DATE_RANGES = [
  DYNAMIC_DATE_ID.LAST_7_DAYS,
  DYNAMIC_DATE_ID.LAST_14_DAYS,
  DYNAMIC_DATE_ID.LAST_28_DAYS,
] as const

export const PRODUCT_SET_DEFAULT_DATE_RANGE = PRODUCT_SET_DATE_RANGES[0]

export const PRODUCT_SET_LABEL_COLUMN_NAMES = [
  { name: 'Custom label 0', id: 'custom_label_0' },
  { name: 'Custom label 1', id: 'custom_label_1' },
  { name: 'Custom label 2', id: 'custom_label_2' },
  { name: 'Custom label 3', id: 'custom_label_3' },
  { name: 'Custom label 4', id: 'custom_label_4' },
]

export const PRODUCT_SET_LABEL_COLUMN_NAMES_MAP =
  PRODUCT_SET_LABEL_COLUMN_NAMES.reduce(
    (acc, label) => {
      acc[label.id] = label.name

      return acc
    },
    {} as Record<string, string>,
  )

export const PRODUCT_SET_LABEL_COLUMN_NAME =
  PRODUCT_SET_LABEL_COLUMN_NAMES[0].id

export const SEGMENTATION_SET_ICON_NAMES: IconName[] = [
  'PlatformLogoGoogleIcon',
  'PlatformLogoMetaIcon',
  'PlatformLogoMicrosoftIcon',
  'PlatformLogoPinterestIcon',
  'PlatformLogoSnapchatIcon',
  'PlatformLogoTikTokIcon',
  'BikeIcon',
  'MotorcycleIcon',
  'CarIcon',
  'ShippingTruckIcon',
  'SailboatIcon',
  'RocketIcon',
  'RobotIcon',
  'LayersIcon',
  'RulerIcon',
  'ChartPieIcon',
  'ChartDonutIcon',
  'BarChartIcon',
  'BlockIcon',
  'ExclamationCircleIcon',
  'ExclamationTriangleIcon',
  'ParkingIcon',
  'DiamondIcon',
  'MedalIcon',
  'HeartIcon',
  'StarIcon',
  'BadgeIcon',
  'DollarCoinIcon',
  'PiggyBankIcon',
  'WalletIcon',
  'SignIcon',
  'MegaphoneIcon',
  'SuitcaseIcon',
  'ShoppingBagIcon',
  'ShirtIcon',
  'Percent1Icon',
  'BadgeDiscountIcon',
  'TagIcon',
  'WarehouseIcon',
  'EarthIcon',
  'Flower1Icon',
  'Flower2Icon',
  'TreeIcon',
  'LeafIcon',
]

export const SEGMENTATION_SET_COLORS: string[] = [
  colorTheme.purple[500],
  colorTheme.magenta[500],
  colorTheme.red[400],
  colorTheme.orange[500],
  colorTheme.yellow[600],
  colorTheme.green[500],
  colorTheme.dema[600],
  colorTheme.cyan[600],
  colorTheme.blue[600],
  colorTheme.black,
  colorTheme.gray[500],
]
