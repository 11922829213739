import { type ColorMode, Flex } from '@chakra-ui/react'
import { InfoTooltip } from 'components/Tooltip/InfoTooltip'
import { Typography } from 'components/Typography'
import { colorTheme } from 'ui/theme/colors'

interface Props {
  isDisabled?: boolean
  label: string
  colorMode?: ColorMode
  htmlFor?: string
  tooltip?: string
}

const getLabelColor = (colorMode: ColorMode, isDisabled?: boolean) => {
  if (isDisabled) return colorTheme.grey[400]
  if (colorMode === 'dark') {
    return colorTheme.grey[100]
  }

  return colorTheme.grey[700]
}

export const Label = ({
  isDisabled,
  label,
  colorMode = 'light',
  htmlFor,
  tooltip,
}: Props) => {
  const labelColor = getLabelColor(colorMode, isDisabled)

  return (
    <Typography
      as="label"
      fontSize="xs"
      mb={1}
      color={labelColor}
      htmlFor={htmlFor}
    >
      {tooltip ? (
        <Flex gap={1} alignItems="center">
          {label}
          <InfoTooltip
            tooltipText={tooltip}
            iconProps={{ size: 'small', color: labelColor }}
          />
        </Flex>
      ) : (
        label
      )}
    </Typography>
  )
}
