import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useEditReport } from 'graphql/reports/useEditReport'

type Props = {
  selectedReport: ReportState
  newOwnerExternalId: string
  oldOwnerExternalId?: string
}

export const useEditReportOwner = () => {
  const [editReport, state] = useEditReport()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const editReportOwner = async ({
    selectedReport,
    newOwnerExternalId,
    oldOwnerExternalId,
  }: Props) => {
    if (!selectedReport.id) return

    try {
      await editReport({
        id: selectedReport.id,
        report: {
          ...selectedReport,
          owner: {
            externalId: newOwnerExternalId,
          },
        },
      })

      trackEvent({
        eventName: 'Report Owner Changed',
        eventProperties: {
          reportId: selectedReport.id,
          newOwnerExternalId,
          oldOwnerExternalId,
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not update report owner, please try again',
      })

      captureException(`Update report owner: ${error}`)
    }
  }

  return { editReportOwner, loading: state.loading }
}
