import { type IconName } from 'components/Icon/Icon'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import {
  OPTIMIZATIONS_PAGES,
  OPTIMIZATIONS_ROUTE_FRAGMENTS,
} from 'features/optimizations/routePages'
import {
  analyticsConfigAtom,
  reportStateAtom,
} from 'features/reports/atoms/reportViewStateAtom'
import { REPORT_PAGES } from 'features/reports/routePages'
import {
  SEGMENTATIONS_PAGES,
  SEGMENTATIONS_ROUTE_FRAGMENTS,
} from 'features/segmentations/routePages'
import { type FeatureFlags } from 'graphql/useFeatureFlags'
import { getStore } from 'shared/store'

export const HEADER_HEIGHT = 12

interface HeaderLink {
  path: string
  label: string
  iconName: IconName
  matchPath?: string
  featureFlag?: keyof FeatureFlags
  onClick?: () => void
  isBeta?: boolean
}

export const headerLinks: HeaderLink[] = [
  {
    path: '/',
    label: 'Dashboard',
    iconName: 'ChartPieIcon',
    featureFlag: 'enableDashboard',
  },
  {
    path: REPORT_PAGES.BASE,
    label: 'Reports',
    matchPath: `${REPORT_PAGES.BASE}/*`,
    iconName: 'CopyIcon',
    onClick: () => {
      // Update for local state to avoid flickering when the state was previously populated
      getStore().set(reportStateAtom, undefined)
      getStore().set(analyticsConfigAtom, undefined)
    },
  },
  {
    path: OPTIMIZATIONS_PAGES.BASE,
    label: 'Optimisations',
    iconName: 'DollarSignIcon',
    matchPath: `/${OPTIMIZATIONS_ROUTE_FRAGMENTS.OPTIMIZATIONS}/*`,
    featureFlag: 'enableOptimizations',
  },
  {
    path: SEGMENTATIONS_PAGES.PRODUCT_SETS,
    label: 'Segmentations',
    iconName: 'ChartDonutIcon',
    matchPath: `/${SEGMENTATIONS_ROUTE_FRAGMENTS.SEGMENTATIONS}/*`,
    featureFlag: 'enableSegmentationProductSet',
  },
  {
    path: GEO_LIFT_PAGES.EXPERIMENTS,
    label: 'Lift testing',
    iconName: 'BeakerIcon',
    matchPath: `${GEO_LIFT_PAGES.BASE}/*`,
    featureFlag: 'enableGeoLift',
  },
  {
    path: '/custom-dashboard',
    label: 'Custom Dashboard',
    iconName: 'BarChartIcon',
    matchPath: '/custom-dashboard/*',
    featureFlag: 'enableCustomDashboard',
    isBeta: true,
  },
  {
    path: '/settings',
    label: 'Settings',
    iconName: 'CogIcon',
    matchPath: '/settings/*',
    featureFlag: 'enableSettings',
  },
]
