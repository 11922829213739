import { type IconName } from 'components/Icon/Icon'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import { type Options } from 'highcharts'
import { type FC } from 'react'
import { type HexColor, colorTheme } from 'ui/theme/colors'
import {
  CHART_PRIMARY_COLOR,
  CHART_PRIMARY_OFFSET_COLOR,
  CHART_SECONDARY_COLOR,
  CHART_SECONDARY_OFFSET_COLOR,
} from 'utils/chart/constants'
import { type ContributionData } from './graphql/useHistoricalAnalysisQuery'

const optimalSuffix = ':optimal'

// keys used to get the values from the mmmm responses
export enum OPTIMIZATION_METRIC {
  SPEND = 'spend',
  SPEND_OPTIMAL = `${OPTIMIZATION_METRIC.SPEND}${optimalSuffix}`,
  NGP3 = 'ngp3',
  NGP3_OPTIMAL = `${OPTIMIZATION_METRIC.NGP3}${optimalSuffix}`,
  EPROAS = 'epRoas',
  EPROAS_OPTIMAL = `${OPTIMIZATION_METRIC.EPROAS}${optimalSuffix}`,
  SALES = 'sales',
  SALES_OPTIMAL = `${OPTIMIZATION_METRIC.SALES}${optimalSuffix}`,
  ROAS = 'roas',
  ROAS_OPTIMAL = `${OPTIMIZATION_METRIC.ROAS}${optimalSuffix}`,
}

export const getOptimalOptimizationMetric = (
  optimizationMetric: OPTIMIZATION_METRIC,
): OPTIMIZATION_METRIC =>
  optimizationMetric.includes(optimalSuffix)
    ? optimizationMetric
    : (`${optimizationMetric}${optimalSuffix}` as OPTIMIZATION_METRIC)

// Map to get the value of the optimization metric from the mmmm response. This is specially helpful for derived metrics like epRoas
export const optimizationsMetricValuesMap = {
  [OPTIMIZATION_METRIC.SPEND]: {
    getValue: (row) => row[OPTIMIZATION_METRIC.SPEND] as number,
  },
  [OPTIMIZATION_METRIC.SPEND_OPTIMAL]: {
    getValue: (row) => row[OPTIMIZATION_METRIC.SPEND_OPTIMAL] as number,
  },
  [OPTIMIZATION_METRIC.NGP3]: {
    getValue: (row) => row[OPTIMIZATION_METRIC.NGP3] as number,
  },
  [OPTIMIZATION_METRIC.NGP3_OPTIMAL]: {
    getValue: (row) => row[OPTIMIZATION_METRIC.NGP3_OPTIMAL] as number,
  },
  [OPTIMIZATION_METRIC.SALES]: {
    getValue: (row) => row[OPTIMIZATION_METRIC.SALES] as number,
  },
  [OPTIMIZATION_METRIC.SALES_OPTIMAL]: {
    getValue: (row) => row[OPTIMIZATION_METRIC.SALES_OPTIMAL] as number,
  },
  [OPTIMIZATION_METRIC.EPROAS]: {
    getValue: (row) =>
      ((row[OPTIMIZATION_METRIC.NGP3] as number) +
        (row[OPTIMIZATION_METRIC.SPEND] as number)) /
      (row[OPTIMIZATION_METRIC.SPEND] as number),
  },
  [OPTIMIZATION_METRIC.EPROAS_OPTIMAL]: {
    getValue: (row) =>
      ((row[OPTIMIZATION_METRIC.NGP3_OPTIMAL] as number) +
        (row[OPTIMIZATION_METRIC.SPEND_OPTIMAL] as number)) /
      (row[OPTIMIZATION_METRIC.SPEND_OPTIMAL] as number),
  },
  [OPTIMIZATION_METRIC.ROAS]: {
    getValue: (row) =>
      Number(row[OPTIMIZATION_METRIC.SALES]) /
      Number(row[OPTIMIZATION_METRIC.SPEND]),
  },
  [OPTIMIZATION_METRIC.ROAS_OPTIMAL]: {
    getValue: (row) =>
      Number(row[OPTIMIZATION_METRIC.SALES_OPTIMAL]) /
      Number(row[OPTIMIZATION_METRIC.SPEND_OPTIMAL]),
  },
} as const satisfies Record<
  OPTIMIZATION_METRIC,
  {
    getValue: (row: Record<string, unknown>) => number
  }
>

export const TARGETS = {
  BUDGET: 'budget',
  ROAS: 'roas',
  EP_ROAS: 'ep_roas',
  OPTIMAL_BUDGET: 'optimal_budget',
} as const

export type TargetType = (typeof TARGETS)[keyof typeof TARGETS]

// List of metrics to be shown in the chart
export const chartMetricIds = [
  OPTIMIZATION_METRIC.SPEND,
  OPTIMIZATION_METRIC.SPEND_OPTIMAL,
  OPTIMIZATION_METRIC.NGP3,
  OPTIMIZATION_METRIC.NGP3_OPTIMAL,
] as const

// List of configurations for the metrics in the chart
export const chartMetricMap = {
  [OPTIMIZATION_METRIC.SPEND]: {
    id: OPTIMIZATION_METRIC.SPEND,
    color: CHART_PRIMARY_COLOR,
    name: 'Actual Marketing spend',
    type: 'column',
    yAxis: '1',
    zIndex: 11,
    striped: null,
    minPointLength: 5,
    zones: [
      {
        value: -5,
      },
      {
        value: 5,
        color: colorTheme.grey[200],
      },
    ],
  },
  [OPTIMIZATION_METRIC.SPEND_OPTIMAL]: {
    id: OPTIMIZATION_METRIC.SPEND_OPTIMAL,
    color: CHART_PRIMARY_OFFSET_COLOR,
    name: 'Proposed Marketing spend',
    type: 'column',
    yAxis: '1',
    zIndex: 10,
    striped: { color: CHART_PRIMARY_COLOR },
    minPointLength: 5,
    zones: [
      {
        value: -5, // This is needed to show correct color for negative values
      },
      {
        value: 5,
        color: colorTheme.grey[200],
      },
    ],
  },
  [OPTIMIZATION_METRIC.NGP3]: {
    id: OPTIMIZATION_METRIC.NGP3,
    color: CHART_SECONDARY_COLOR,
    name: 'Actual Net gross profit 3',
    type: 'column',
    yAxis: '1',
    zIndex: 11,
    minPointLength: 5,
    zones: [
      {
        value: -5, // This is needed to show correct color for negative values
      },
      {
        value: 5,
        color: colorTheme.grey[200],
      },
    ],
    striped: null,
  },
  [OPTIMIZATION_METRIC.NGP3_OPTIMAL]: {
    id: OPTIMIZATION_METRIC.NGP3_OPTIMAL,
    color: CHART_SECONDARY_OFFSET_COLOR,
    name: 'Estimated Net gross profit 3',
    type: 'column',
    yAxis: '1',
    zIndex: 10,
    striped: { color: CHART_SECONDARY_COLOR },
    minPointLength: 5,
    zones: [
      {
        value: -5, // This is needed to show correct color for negative values
      },
      {
        value: 5,
        color: colorTheme.grey[200],
      },
    ],
  },
} as const satisfies Record<
  string,
  NonNullable<Options['series']>[number] & {
    striped: { color: HexColor } | null
  }
>

export type MetricOptimizationTableColumn = {
  CellComponent?: never
  format: MetricFormat
  includeActualValue?: boolean
  isHidden?: boolean
  isHighlighted?: boolean
  isMetric: true
  key: OPTIMIZATION_METRIC
  label: string
  excludeUnoptimizableChannels?: boolean
}
export type OptimizationTableColumn =
  | MetricOptimizationTableColumn
  | {
      CellComponent: FC<{
        channelContribution: ContributionData[number] | undefined
      }>
      format?: never
      includeActualValue?: never
      isHidden?: never
      isHighlighted?: never
      isMetric: false
      key: string
      label: string
    }

// Configuration for the columns in the scenario table
export const scenarioColumns: OptimizationTableColumn[] = [
  {
    key: OPTIMIZATION_METRIC.SPEND,
    label: 'Marketing spend',
    format: METRIC_FORMAT.CURRENCY,
    includeActualValue: true,
    isHighlighted: true,
    isMetric: true,
    excludeUnoptimizableChannels: true,
  },
  {
    key: OPTIMIZATION_METRIC.SALES,
    label: 'Gross sales',
    format: METRIC_FORMAT.CURRENCY,
    isMetric: true,
  },
  {
    key: OPTIMIZATION_METRIC.NGP3,
    label: 'Net gross profit 3',
    format: METRIC_FORMAT.CURRENCY,
    isMetric: true,
  },
  {
    key: OPTIMIZATION_METRIC.ROAS,
    label: 'ROAS',
    format: METRIC_FORMAT.PERCENT,
    isMetric: true,
  },
  {
    key: OPTIMIZATION_METRIC.EPROAS,
    label: 'epROAS',
    format: METRIC_FORMAT.PERCENT,
    isMetric: true,
  },
]

export enum CompareValueType {
  Number = 'number',
  Percentage = 'percentage',
}

export const compareValuesTypes: {
  id: CompareValueType
  iconName: IconName
  title: string
}[] = [
  {
    id: CompareValueType.Percentage,
    iconName: 'Percent1Icon',
    title: 'Show compare value as percentage',
  },
  {
    id: CompareValueType.Number,
    iconName: 'HashtagIcon',
    title: 'Show compare value as number',
  },
]
