import { captureException } from '@sentry/core'
import { MANUAL_INPUT_FORMAT } from '../../consts'
import {
  type ManualInputColumns,
  type TaxonomyType,
  type TaxonomyInput,
  type Matcher,
} from '../../types'
import { getFallbackValueByFormat } from './common'

const getDefault = (row: ManualInputColumns) => {
  const { currency, perOrder, perItem, perShipment, percentage } = row

  return {
    currency: getFallbackValueByFormat(MANUAL_INPUT_FORMAT.CURRENCY, currency),
    perOrder:
      perOrder ??
      getFallbackValueByFormat(MANUAL_INPUT_FORMAT.NUMBER_CURRENCY, currency),
    perItem:
      perItem ??
      getFallbackValueByFormat(MANUAL_INPUT_FORMAT.NUMBER_CURRENCY, currency),
    perShipment:
      perShipment ??
      getFallbackValueByFormat(MANUAL_INPUT_FORMAT.NUMBER_CURRENCY, currency),
    percentage:
      percentage ??
      getFallbackValueByFormat(MANUAL_INPUT_FORMAT.PERCENTAGE, currency),
  }
}

const getTaxonomyMatchers = (subRows?: ManualInputColumns[]) => {
  return subRows?.reduce<Record<string, Matcher>>((acc, row) => {
    const { name, subRows } = row
    const nextCondition = subRows?.[0]?.groupName

    if (!name) {
      captureException(
        `Manual input: Taxonomy row is invalid. All default values are empty or name is undefined. Skipping row ${row}.`,
      )

      return acc
    }

    acc[name] = {
      default: getDefault(row),
      nextCondition: nextCondition ?? null,
      matchers: getTaxonomyMatchers(subRows) ?? {},
    }

    return acc
  }, {})
}

type Props = {
  data: ManualInputColumns
  taxonomyType: TaxonomyType
}

export const mapTableDataToTaxonomy = ({ data, taxonomyType }: Props) => {
  const { subRows } = data

  const taxonomy: TaxonomyInput = {
    type: taxonomyType,
    rootMatcher: {
      default: getDefault(data),
      nextCondition: subRows?.[0]?.groupName ?? null,
      matchers: getTaxonomyMatchers(subRows) ?? {},
    },
  }

  return taxonomy
}
