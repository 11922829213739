import { Flex } from '@chakra-ui/react'
import { FilterOperator } from 'components/Filters/types'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type Dispatch, type SetStateAction } from 'react'
import { type ExperimentOptionFields, type ExperimentFilters } from './consts'
import { ExperimentsFilterBadge } from './ExperimentsFilterBadge'
import { ExperimentsFilterPicker } from './ExperimentsFilterPicker'

interface Props {
  experiments: ExperimentSummary[]
  filters: ExperimentFilters
  setFilters: Dispatch<SetStateAction<ExperimentFilters>>
}

export const ExperimentsFilter = ({
  filters,
  setFilters,
  experiments,
}: Props) => {
  const [trackEvent] = useTrackEvent()
  const updateFilters = (
    option: ExperimentOptionFields,
    newFilters: string[],
  ) => {
    trackEvent({
      eventName: 'GeoLift Experiments Filter Changed',
      eventProperties: {
        type: option,
        oldFilters: filters[option],
        newFilters: newFilters,
        filterType: FilterOperator.isAnyOf,
      },
    })

    setFilters((prev) => ({
      ...prev,
      [option]: newFilters,
    }))
  }

  return (
    <Flex gap={2} wrap="wrap" alignItems="center" maxW="100%">
      {Object.entries(filters).map(
        ([option, values]) =>
          values.length > 0 && (
            <ExperimentsFilterBadge
              key={option}
              filterValues={values}
              option={option as ExperimentOptionFields}
              experiments={experiments}
              setFilters={updateFilters}
              onRemoveFilter={() =>
                updateFilters(option as ExperimentOptionFields, [])
              }
            />
          ),
      )}
      <ExperimentsFilterPicker
        filters={filters}
        experiments={experiments}
        setFilters={updateFilters}
      />
    </Flex>
  )
}
