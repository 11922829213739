import {
  Box,
  MenuButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { useDimensionsState } from 'features/reports/hooks/useDimensionsState'
import { useReportsAnalytics } from 'features/reports/hooks/useReportsAnalytics'
import { type SegmentationSet } from 'features/segmentations/graphql/fragments'
import { useSegmentationSetById } from 'features/segmentations/graphql/useSegmentationSetById'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import { useSetSegmentationRules } from 'features/segmentations/views/ProductRulesView/graphql/useSetSegmentationRules'
import { useSegmentationSets } from 'features/segmentations/views/ProductSetsView/graphql/useSegmentationSets'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { uniq } from 'lodash-es'
import { useState } from 'react'
import { useNavigate } from 'react-router'

export const ExportToProductSetButton: React.FC = () => {
  const { enableSegmentationProductSet } = useFeatureFlags()
  const { dimensionKeys } = useDimensionsState()
  const { data } = useReportsAnalytics({ fetchPolicy: 'cache-only' })
  const { segmentationSets, query: segmentationQuery } = useSegmentationSets()

  if (!enableSegmentationProductSet) {
    return null
  }

  if (
    !dimensionKeys.find((id) => id === 'product') ||
    segmentationQuery.loading ||
    segmentationSets.length === 0
  ) {
    return (
      <Tooltip
        placement="bottom"
        label="Make sure to select product id dimension and create at least one product set"
      >
        <Button isDisabled size="sm" variant="outline" colorScheme="gray">
          Add to segmentations
        </Button>
      </Tooltip>
    )
  }

  const productIds = data
    ?.map((row) => row.product.value)
    .filter(Boolean) as string[]
  const uniqueProductIds = uniq(productIds)

  return (
    <ExportSetModal
      key={uniqueProductIds.join('-')}
      uniqueProductIds={uniqueProductIds}
      segmentationSets={segmentationSets}
    />
  )
}

export const ExportSetModal: React.FC<{
  uniqueProductIds: string[]
  segmentationSets: SegmentationSet[]
}> = ({ uniqueProductIds, segmentationSets }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedIds, setSelectedIds] = useState(uniqueProductIds)
  const [selectedSegmentationSet, setSelectedSegmentationSet] = useState(
    segmentationSets[0],
  )
  const { segmentationSet, query: segmentationQuery } = useSegmentationSetById(
    selectedSegmentationSet.id,
  )
  const [setSegmentationRules, setSegmentationRulesState] =
    useSetSegmentationRules()

  const toast = useToast()
  const navigate = useNavigate()

  const onSubmit = () => {
    if (!segmentationSet) return

    const newOverrideRule = {
      filters: {
        product: [
          {
            comparisonId: 'isAnyOf',
            value: selectedIds,
          },
        ],
      },
      description: null,
      productLabelId: null,
      expiresAt: null,
    }

    setSegmentationRules({
      variables: {
        params: {
          rules: [
            newOverrideRule,
            ...segmentationSet.rules.map((rule) => ({
              description: rule.description,
              filters: rule.filters,
              productLabelId: rule.productLabel?.id,
              expiresAt: rule.expiresAt,
            })),
          ],
          segmentationSetId: segmentationSet.id,
        },
      },
      optimisticResponse: {
        setSegmentationRules: {
          ...segmentationSet,
          rules: [
            {
              id: 'optimistic',
              __typename: 'SegmentationRule' as const,
              ...newOverrideRule,
            },
            ...segmentationSet.rules,
          ],
        },
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: 'SegmentationSet',
            id: segmentationSet.id,
          }),
          fields: {
            rules: () => data?.setSegmentationRules.rules,
          },
        })
      },
      onCompleted: () => {
        navigate(SEGMENTATIONS_PAGES.PRODUCT_RULES_BY_ID(segmentationSet.id))
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Failed to create rule',
        })
      },
    })
  }

  const buttonIsDisabled = uniqueProductIds.length === 0

  return (
    <>
      <Tooltip
        placement="bottom"
        label="No products selected"
        isDisabled={!buttonIsDisabled}
      >
        <Button
          onClick={onOpen}
          isDisabled={buttonIsDisabled}
          size="sm"
          variant="outline"
          colorScheme="gray"
        >
          Add to segmentations
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage in segmentations</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <p>Manage the selected products in a product set.</p>

            <Box mt={4}>
              <Typography
                fontSize="xs"
                color="gray.700"
                fontWeight={500}
                lineHeight={4}
                mb={1}
              >
                Products
              </Typography>
              <ComboBox
                selectionMode={SELECTION_MODE.MULTIPLE}
                customMenuButton={
                  <MenuButton
                    as={Button}
                    size="sm"
                    colorScheme="gray"
                    w="max-content"
                    maxW="full"
                    overflow="hidden"
                    leadingIcon={{ name: 'CubeIcon' satisfies IconName }}
                  >
                    {selectedIds.length === 0 ? (
                      'Select Product IDs'
                    ) : (
                      <Typography overflow="hidden" textOverflow="ellipsis">
                        Product ID is{' '}
                        <Typography as="span" fontWeight={500}>
                          {selectedIds[0]}
                          {selectedIds.length > 1 && (
                            <> and {selectedIds.length - 1} more</>
                          )}
                        </Typography>
                      </Typography>
                    )}
                  </MenuButton>
                }
                menuProps={{
                  closeOnBlur: true,
                }}
                options={uniqueProductIds.map((id) => ({
                  id: id,
                  name: String(id),
                }))}
                selected={selectedIds}
                setSelected={(selected) => setSelectedIds(selected)}
              />
            </Box>

            <Box mt={4}>
              <Typography
                fontSize="xs"
                color="gray.700"
                fontWeight={500}
                lineHeight={4}
                mb={1}
              >
                Product set
              </Typography>

              <Dropdown
                callback={(set) => {
                  setSelectedSegmentationSet(set)
                }}
                size="sm"
                customMenuButton={
                  <MenuButton
                    as={Button}
                    size="sm"
                    colorScheme="gray"
                    w="max-content"
                    leadingIcon={{
                      name: selectedSegmentationSet.iconName,
                      color: selectedSegmentationSet.iconColor,
                    }}
                  >
                    {selectedSegmentationSet.name}
                  </MenuButton>
                }
                options={segmentationSets.map((set) => ({
                  ...set,
                  leftItem: (
                    <Icon
                      name={set.iconName}
                      color={set.iconColor}
                      size="small"
                    />
                  ),
                }))}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                onSubmit()
              }}
              isLoading={
                segmentationQuery.loading || setSegmentationRulesState.loading
              }
              isDisabled={!segmentationSet}
            >
              Manage in Segmentations
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
