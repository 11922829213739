import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Dropdown, type DropdownOption } from 'components/Dropdown'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { graphql } from 'generated/graphql'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo, useState } from 'react'
import { AddMarketingCostSidebar } from './AddMarketingCostSidebar/AddMarketingCostSidebar'
import { MarketingCostTable } from './MarketingCostTable/MarketingCostTable'

export const MARKETING_COST_VIEW_QUERY = graphql(/* GraphQL */ `
  query MarketingCostsViewQuery($merchantSiteParams: MerchantSiteInput!) {
    viewer {
      id
      merchantSite(params: $merchantSiteParams) {
        frontendId
        ...MarketingCostManualInputs_MerchantSite
      }
    }
  }
`)

export const MarketingCostsView = () => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const flags = useFeatureFlags()
  const { frontendId, sites } = useMerchantInfo()

  const isReadOnly = !flags.allowSettingsWrite
  const siteOptions = useMemo(
    () =>
      (sites ?? []).map((site) => ({ id: site.frontendId, name: site.name })),
    [sites],
  )
  const [selectedMerchantSite, setSelectedMerchantSite] =
    useState<DropdownOption>(
      siteOptions[0] ?? { id: frontendId, name: frontendId },
    )

  const showSiteDropdown = siteOptions.length > 1

  const { data, loading, error } = useQuery(MARKETING_COST_VIEW_QUERY, {
    variables: {
      merchantSiteParams: { frontendId: String(selectedMerchantSite.id) },
    },
  })

  return (
    <>
      <TitleLayoutView
        title="Marketing costs"
        description="Input manual marketing costs in addition to the costs that are fetched from your integrated ad platforms"
        buttonsContent={
          <>
            {isReadOnly ? undefined : (
              <Button size="sm" onClick={onOpen}>
                Add marketing costs
              </Button>
            )}

            {showSiteDropdown ? (
              <Dropdown
                value={selectedMerchantSite?.id}
                options={siteOptions}
                callback={setSelectedMerchantSite}
                size="sm"
                variant="outline"
                matchWidth={false}
                buttonProps={{ size: 'sm', variant: 'outline' }}
              />
            ) : undefined}
          </>
        }
      >
        <MarketingCostTable
          merchantSite={data?.viewer?.merchantSite}
          isReadOnly={isReadOnly}
          key={selectedMerchantSite.id}
          isLoading={loading}
          isError={Boolean(error)}
        />
      </TitleLayoutView>
      <AddMarketingCostSidebar
        isOpen={isOpen}
        onClose={onClose}
        frontendId={String(selectedMerchantSite.id)}
      />
    </>
  )
}
