import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { EXPERIMENT_QUERY } from './useExperiment'

const UPDATE_RESULTS_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateResults($id: ID!) {
    runExperimentInference(id: $id)
  }
`)

export const useUpdateResults = () => {
  return useMutation(UPDATE_RESULTS_MUTATION, {
    refetchQueries: [EXPERIMENT_QUERY],
  })
}
