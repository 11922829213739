import { type TimeUnit } from 'graphql/statistics/types'
import {
  type YAxisPlotLinesOptions,
  type PatternObject,
  type TooltipOptions,
} from 'highcharts'
import { colorTheme, type ColorValue, hexToColorType } from 'ui/theme/colors'

export const CHART_PRIMARY_COLOR = colorTheme.purple[500]
export const CHART_PRIMARY_OFFSET_COLOR = colorTheme.purple[200]
export const CHART_PRIMARY_LIGHT_OFFSET_COLOR = colorTheme.purple[100]
export const CHART_PRIMARY_BADGE_COLORS = {
  color: colorTheme.blue[600],
  backgroundColor: colorTheme.blue[200],
}

export const CHART_SECONDARY_COLOR = colorTheme.cyan[500]
export const CHART_SECONDARY_OFFSET_COLOR = colorTheme.cyan[200]

export const CHART_COMPARE_COLOR = colorTheme.purple[200]

export const CHART_COLORS = [
  CHART_PRIMARY_COLOR,
  CHART_SECONDARY_COLOR,
  colorTheme.magenta[400],
  colorTheme.orange[400],
  colorTheme.blue[400],
  colorTheme.yellow[500],

  colorTheme.purple[400],
  colorTheme.cyan[600],
  colorTheme.magenta[500],
  colorTheme.orange[600],
  colorTheme.blue[500],
  colorTheme.yellow[600],

  colorTheme.purple[600],
  colorTheme.cyan[400],
  colorTheme.magenta[600],
  colorTheme.orange[300],
  colorTheme.blue[600],
  colorTheme.yellow[400],

  colorTheme.purple[300],
  colorTheme.cyan[700],
  colorTheme.magenta[300],
  colorTheme.orange[700],
  colorTheme.blue[300],
  colorTheme.yellow[700],

  colorTheme.purple[700],
  colorTheme.cyan[300],
  colorTheme.magenta[700],
  colorTheme.orange[600],
  colorTheme.blue[700],
  colorTheme.yellow[300],
]

// Is used for taking a color like "purple.500" and getting the compare color for it, which is "purple.200"
// Used in chart series
export const getChartOffsetColorFromIndex = (index: number) => {
  return colorTheme[
    hexToColorType(CHART_COLORS[index % CHART_COLORS.length]).split(
      '.',
    )[0] as ColorValue
  ][200]
}
export const staticChartOptions = {
  colors: CHART_COLORS,
  title: {
    text: undefined,
    style: {
      fontWeight: '500',
      color: colorTheme.grey[800],
      fontSize: '14',
    },
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    symbolHeight: 10,
    symbolWidth: 5,
    symbolPadding: 8,
    itemStyle: {
      fontWeight: 'normal',
      textOverflow: 'ellipsis',
      width: 120,
      color: colorTheme.grey[800],
    },
    itemMarginBottom: 8,
  } as Highcharts.LegendOptions,
  plotOptions: {
    series: {
      animation: false,
      stickyTracking: false,
      connectNulls: true,
      marker: {
        // This makes the marker to be shown in the legend but not on the lines
        enabledThreshold: Infinity,
        symbolHeight: 10,
        symbol: 'circle',
      },
      turboThreshold: undefined,
      lineWidth: 2,
      borderRadius: 0,
      threshold: 0, // This is to make charts with more than one axis to align the axis at 0
    },
  },
  tooltip: {
    animation: false,
    backgroundColor: '#FFFFFF',
    borderColor: 'transparent',
    style: {
      fontSize: '12px',
      boxShadow:
        '0px 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 2px 6px 0px rgba(0, 0, 0, 0.16)',
    },
    padding: 16,
    shape: 'rect',
    borderRadius: 4,
    outside: true,
    useHTML: true,
    hideDelay: 0,
  } satisfies TooltipOptions,
  yAxis: {
    gridLineWidth: 0,
    title: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        color: colorTheme.grey[700],
      },
    },
    labels: {
      distance: 16,
      style: { color: colorTheme.grey[600], fontSize: '10px' },
    },
  },
  xAxis: {
    minPadding: 0,
    title: {
      margin: 10,
      style: {
        fontWeight: '500',
        fontSize: '12px',
        color: colorTheme.grey[700],
      },
    },
    labels: {
      distance: 8,
      style: { color: colorTheme.grey[600], fontSize: '10px' },
    },
    lineColor: colorTheme.gray[400], // Bottom border
    tickLength: 0, // Hide ticks
  } satisfies Highcharts.XAxisOptions,
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  noData: {
    style: {
      fontWeight: '500',
      fontSize: '14px',
      color: colorTheme.grey[800],
      textAlign: 'center',
    },
    useHTML: true,
  },
  loading: {
    labelStyle: {
      fontWeight: '500',
      fontSize: '14px',
      color: colorTheme.grey[800],
      textAlign: 'center',
    },
    style: {
      opacity: 0.9,
    },
  },
  lang: {
    noData:
      'No data available<br><p>Try running a new query with other parameters</p>',
    loading:
      'Loading...<br><p>Please be patient, we are connecting to your database</p>',
  },
  chart: {
    animation: false,
    inverted: false,
  },
  boost: {
    pixelRatio: 0,
    useGPUTranslations: false,
    usePreallocated: false,
    enabled: true,
  },
}

export const getChartStripedPattern = ({
  backgroundColor,
  stroke,
}: {
  backgroundColor: string
  stroke: string
}): PatternObject => {
  return {
    pattern: {
      backgroundColor,
      path: {
        d: 'M 0 10 L 10 0 M 9 11 L 11 9 M -1 1 L 1 -1',
        stroke,
        strokeWidth: 3.5,
      },
      width: 10,
      height: 10,
    },
  }
}

export const yAxisZeroPlotLine = [
  {
    value: 0,
    color: colorTheme.grey[300],
    width: 1,
    zIndex: 1,
    dashStyle: 'Dot',
  },
] satisfies YAxisPlotLinesOptions[]

export const maxNumberOfGroupsForScatterChart = 50

export const SERIES_MAP_KEY = {
  ACTUAL: 'actual',
  COMPARE: 'compare',
  PREDICTED: 'predicted',
} as const

export const seriesMap = {
  actual: 'Selected period',
  compare: 'Compare period',
  predicted: 'Predicted data',
} as const satisfies Record<
  (typeof SERIES_MAP_KEY)[keyof typeof SERIES_MAP_KEY],
  string
>

export const hourDimension: TimeDimension = { id: 'hour', name: 'Hours' }
export const dayDimension: TimeDimension = { id: 'day', name: 'Days' }
export const weekDimension: TimeDimension = { id: 'week', name: 'Weeks' }
export const monthDimension: TimeDimension = { id: 'month', name: 'Months' }
export const yearDimension: TimeDimension = { id: 'year', name: 'Years' }
export const quarterDimension: TimeDimension = {
  id: 'quarter',
  name: 'Quarters',
}

interface TimeDimension {
  id: TimeUnit
  name: string
}

export const timeDimensions: TimeDimension[] = [
  hourDimension,
  dayDimension,
  weekDimension,
  monthDimension,
  quarterDimension,
  yearDimension,
]

export const normalizedTimeDimensions = timeDimensions.reduce(
  (acc, next) => {
    acc[next.id] = next

    return acc
  },
  {} as Record<TimeUnit, TimeDimension>,
)

const timeUnits = ['hour', 'day', 'week', 'month', 'quarter', 'year']

export const isTimeUnit = (dimension: string): dimension is TimeUnit =>
  timeUnits.includes(dimension)

export const compareColor = { id: 'compare', name: 'Compare date' }

export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const AXIS_TYPE = {
  LINEAR: 'linear',
  DATETIME: 'datetime',
  CATEGORY: 'category',
} as const
