import { Box, Skeleton } from '@chakra-ui/react'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { TooltipSectionLabel } from 'features/dashboard/components/ChartTooltip/shared/TooltipSectionLabel'
import { useExperimentGeography } from 'features/geoLift/graphql/useExperimentGeography'
import { type Location } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import HighchartsMaps from 'highcharts/highmaps'
import HighchartsReact from 'highcharts-react-official'
import { useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import {
  CHART_PRIMARY_COLOR,
  CHART_SECONDARY_COLOR,
  staticChartOptions,
} from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'

interface Props {
  zoneType: string
  country: string
  treatmentGroup: Location[]
  controlGroup: Location[]
}

interface PointWithMetaData extends Highcharts.Point {
  metaData: number
}
const isPointWithMetaData = (
  point: Highcharts.Point,
): point is PointWithMetaData =>
  'metaData' in point && point.metaData !== undefined

interface AggregateDataGroup {
  count: number
  totalWeight?: number
}
const aggregateDataGroup = (group: Location[]) => {
  return group.reduce<Record<string, AggregateDataGroup>>((acc, location) => {
    const zone = location.commutingZone

    if (!acc[zone]) {
      acc[zone] = { count: 0, totalWeight: undefined }
    }
    acc[zone].count++
    if (location.weight) {
      acc[zone].totalWeight = (acc[zone].totalWeight ?? 0) + location.weight
    }

    return acc
  }, {})
}

export const MapChart = ({
  zoneType,
  country,
  treatmentGroup,
  controlGroup,
}: Props) => {
  const { experimentGeography } = useExperimentGeography({
    country,
    zoneType,
  })
  const options: Highcharts.Options = useMemo(
    () => ({
      ...staticChartOptions,
      legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal',
      },
      chart: {
        ...staticChartOptions.chart,
        map: experimentGeography,
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
          align: 'right',
        },
      },
      plotOptions: {
        ...staticChartOptions.plotOptions,
        series: {
          allAreas: false,
        },
        map: {
          joinBy: 'name',
          keys: ['name', 'value'],
        },
      },
      series: [
        {
          type: 'map',
          allAreas: true,
          showInLegend: false,
        },
        {
          color: CHART_PRIMARY_COLOR,
          type: 'map',
          data: Object.entries(aggregateDataGroup(treatmentGroup)).map(
            ([commutingZone, { count }]) => ({
              name: commutingZone,
              value: count,
            }),
          ),
          name: 'Treatment',
        },
        {
          color: CHART_SECONDARY_COLOR,
          type: 'map',
          data: Object.entries(aggregateDataGroup(controlGroup)).map(
            ([commutingZone, { count, totalWeight }]) => ({
              name: commutingZone,
              value: count,
              metaData: totalWeight ? totalWeight / count : undefined,
            }),
          ),
          name: 'Control',
        },
      ],
      tooltip: {
        ...staticChartOptions.tooltip,
        shared: true,
        useHTML: true,
        formatter: function () {
          if (!this.point) return ''
          const element = (
            <div>
              <TooltipSectionLabel label={this.point.name} />
              <TooltipMetricRow
                iconColor={this.point.color?.toString()}
                metricName={'Group'}
                value={this.series.name}
              />
              <TooltipMetricRow
                metricName={'Zip codes'}
                value={String(this.point.value)}
              />
              {isPointWithMetaData(this.point) && (
                <TooltipMetricRow
                  metricName={'Weight'}
                  value={
                    this.point.metaData < 0.01
                      ? '< 1%'
                      : formatMetric(METRIC_FORMAT.PERCENT, this.point.metaData)
                  }
                />
              )}
            </div>
          )

          return renderToString(element)
        },
      },
    }),
    [experimentGeography, treatmentGroup, controlGroup],
  )

  return (
    <Box
      overflow="hidden"
      maxW="400px"
      w="full"
      minH="400px"
      maxH="400px"
      marginX="auto"
      mb={4}
    >
      {!experimentGeography ? (
        <Skeleton w="100%" h="100%" />
      ) : (
        <HighchartsReact
          highcharts={HighchartsMaps}
          options={options}
          constructorType="mapChart"
        />
      )}
    </Box>
  )
}
