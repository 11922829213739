import { Input } from 'components/Input'
import type React from 'react'
import { type FieldError } from 'react-hook-form'
import { EXPERIMENT_METRIC_IDS } from './ExperimentConfigDrawer'

interface TargetReturnOnSpendInputProps {
  targetVariable: string | undefined
  targetReturnOnSpend: number | undefined
  error: FieldError | undefined
  onChange: (value: number | undefined) => void
}

export const TargetReturnOnSpendInput: React.FC<
  TargetReturnOnSpendInputProps
> = ({ targetVariable, targetReturnOnSpend, error, onChange }) => {
  if (!targetVariable) {
    return (
      <Input
        label="Expected ROAS/epROAS"
        value="Select Target KPI first"
        helperText="%"
        disabled={true}
      />
    )
  }

  return (
    <Input
      name="targetReturnOnSpend"
      label={
        targetVariable === EXPERIMENT_METRIC_IDS.ORDER_TOTAL
          ? 'Expected ROAS'
          : 'Expected epROAS'
      }
      placeholder={
        targetVariable === EXPERIMENT_METRIC_IDS.ORDER_TOTAL
          ? 'Expected ROAS'
          : 'Expected epROAS'
      }
      type="number"
      labelTooltip="Expected average return on ad spend for the selected channel and funnels. Used to estimate the cost and incrementality of the experiment."
      value={targetReturnOnSpend}
      onChange={(e) => {
        if (e.target.value === '') {
          onChange(undefined)
        } else {
          onChange(Number(e.target.value))
        }
      }}
      helperText="%"
      min="0"
      error={error}
    />
  )
}
