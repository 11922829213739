import { Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { FilterOperator } from 'components/Filters/types'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { PopupButton } from 'features/reports/components/Filters/SelectedFilterChip/PopupButton'
import { type ExperimentOptionFields, filterOptions } from './consts'
import { useUniqueFilterOptions } from './useUniqueFilterOptions'

interface Props {
  onRemoveFilter: () => void
  option: ExperimentOptionFields
  setFilters: (option: ExperimentOptionFields, values: string[]) => void
  filterValues: string[]
  experiments: ExperimentSummary[]
}

export const ExperimentsFilterBadge = ({
  experiments,
  option,
  onRemoveFilter,
  filterValues,
  setFilters,
}: Props) => {
  const options = useUniqueFilterOptions(experiments, option)

  const values = options.filter(({ id }) => filterValues.includes(id))
  const filter = {
    comparisonId: FilterOperator.isAnyOf,
    value: filterValues,
  }

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      customMenuButton={
        <Flex alignItems="stretch" minW="fit-content">
          <PopupButton
            options={values}
            label={filterOptions.find(({ id }) => id === option)?.name ?? ''}
            filter={filter}
          />

          <ButtonIcon
            name="CloseIcon"
            title="Remove filter"
            size="sm"
            h="unset"
            maxH="unset"
            onClick={onRemoveFilter}
          />
        </Flex>
      }
      options={options}
      selected={filterValues}
      setSelected={(selected) => setFilters(option, selected)}
    />
  )
}
