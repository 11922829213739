import { Box } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import type React from 'react'
import { AddProductLabelModal } from './components/AddProductLabelModal'
import { ProductLabelsList } from './components/ProductLabelsList'

export const ProductLabelsView: React.FC = () => {
  return (
    <Box>
      <SegmentationHeaderWrapper>
        <SegmentationHeaderWrapper.Title>
          Product labels
        </SegmentationHeaderWrapper.Title>

        <AddProductLabelModal
          modalButton={
            <Button
              size="sm"
              leadingIcon={{ name: 'PlusSmallIcon' }}
              colorScheme="gray"
            >
              New product label
            </Button>
          }
        />
      </SegmentationHeaderWrapper>

      <ProductLabelsList />
    </Box>
  )
}
