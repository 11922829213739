import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { useRef } from 'react'
import { type ExperimentStatus, type Experiment } from './fragments'

export const EXPERIMENT_QUERY = graphql(/* GraphQL */ `
  query Experiment($id: ID!) {
    viewer {
      id
      merchant {
        id
        experiment(id: $id) {
          ...ExperimentFields
        }
      }
    }
  }
`)

const POLL_INTERVAL = 30000

const isExperimentConfiguring = (
  experimentStatus: ExperimentStatus | undefined,
) => experimentStatus === 'configuring'

export const useExperiment = (id: string) => {
  const experimentStatusRef = useRef<ExperimentStatus | undefined>(undefined)

  const { data, ...query } = useQuery(EXPERIMENT_QUERY, {
    variables: { id },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
    skipPollAttempt: () => {
      // Poll only if the experiment is in configuring state
      return !isExperimentConfiguring(experimentStatusRef.current)
    },
    onCompleted: (data) => {
      experimentStatusRef.current = data?.viewer?.merchant.experiment
        ?.status as ExperimentStatus | undefined
    },
  })

  return {
    query,
    experiment: data?.viewer?.merchant.experiment as Experiment | null,
  }
}
