import {
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { useDeleteExperiment } from 'features/geoLift/graphql/useDeleteExperiment'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'

interface DeleteExperimentModalProps {
  experiment: ExperimentSummary
  onDelete?: () => void
}

export const DeleteExperimentModal: React.FC<DeleteExperimentModalProps> = ({
  experiment,
  onDelete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { deleteExperiment, loading } = useDeleteExperiment()
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const handleDelete = () => {
    deleteExperiment({
      variables: {
        id: experiment.id,
      },
      update: (cache, { data }) => {
        if (!data?.deleteExperiment) return

        // Evict both ExperimentSummary and Experiment types from cache
        cache.evict({
          id: cache.identify({
            ...data.deleteExperiment,
            __typename: 'ExperimentSummary',
          }),
        })
        cache.evict({
          id: cache.identify({
            ...data.deleteExperiment,
            __typename: 'Experiment',
          }),
        })
        cache.gc()
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'GeoLift Experiment Deleted',
          eventProperties: {
            experimentId: experiment.id,
          },
        })
        onDelete?.()
        onClose()
      },
      onError: () => {
        toast({
          description: 'Could not delete experiment. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <MenuItem onClick={onOpen} icon={<Icon name="TrashIcon" size="small" />}>
        Delete experiment
      </MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete experiment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Typography>
              The experiment{' '}
              <Typography as="span" fontWeight={600}>
                {experiment.name}
              </Typography>{' '}
              will be deleted and removed from all instances where it is used.
            </Typography>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button onClick={onClose} colorScheme="gray" variant="outline">
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              isLoading={loading}
              colorScheme="red"
              type="submit"
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
