import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'

import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import type React from 'react'
import { useEditReportOwner } from './useEditReportOwner'

interface EditReportOwnerModalProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  report: ReportState
  selectedOwner?: OrganizationMembershipResource
  onSubmit: (userId: string) => void
}

export const EditReportOwnerModal: React.FC<EditReportOwnerModalProps> = ({
  isOpen,
  onClose,
  report,
  selectedOwner,
  onSubmit,
}) => {
  const { editReportOwner, loading } = useEditReportOwner()

  const handleSubmit = async () => {
    if (!selectedOwner?.publicUserData.userId) return

    await editReportOwner({
      selectedReport: report,
      newOwnerExternalId: selectedOwner?.publicUserData.userId,
      oldOwnerExternalId: report.owner?.id,
    })
    onSubmit(selectedOwner?.publicUserData.userId)
  }

  const ownerName =
    selectedOwner?.publicUserData.firstName +
    ' ' +
    selectedOwner?.publicUserData.lastName

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transfer ownership</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography>
            The report&apos;s ownership will be transferred to {ownerName}
          </Typography>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button onClick={onClose} colorScheme="gray" variant="outline">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={loading}
            colorScheme="blue"
            type="submit"
          >
            Transfer ownership
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
