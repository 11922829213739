import { Flex, SimpleGrid } from '@chakra-ui/react'
import { InfoTooltip } from 'components/Tooltip/InfoTooltip'
import { Typography } from 'components/Typography'

export const DetailRow: React.FC<{
  label: string
  value: string | React.ReactElement
  tooltip?: string
}> = ({ label, value, tooltip }) => {
  return (
    <SimpleGrid
      gridTemplateColumns="auto 1fr"
      py={2.5}
      justifyContent="space-between"
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      _last={{
        borderBottom: 'none',
      }}
    >
      <Flex alignItems="center" mr={4} gap={1}>
        <Typography
          fontSize="xs"
          fontWeight={500}
          lineHeight={5}
          color="gray.600"
        >
          {label}
        </Typography>
        {tooltip && (
          <InfoTooltip
            tooltipText={tooltip}
            iconProps={{ size: 'small', color: 'gray.600' }}
          />
        )}
      </Flex>
      {typeof value === 'string' ? (
        <Flex alignItems="center" justifyContent="end">
          <Typography
            fontSize="sm"
            fontWeight={500}
            lineHeight={4}
            color="gray.800"
            textAlign="right"
          >
            {value}
          </Typography>
        </Flex>
      ) : (
        value
      )}
    </SimpleGrid>
  )
}
