import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { CopyCSVLinkModal } from 'features/segmentations/shared/CopyCSVLinkModal'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import { useState } from 'react'
import { AddProductSetModal } from './AddProductSetModal'

export const ProductSetsHeader: React.FC = () => {
  const [createdProductSet, setCreatedProductSet] = useState<
    { name: string; id: string } | undefined
  >(undefined)
  const csvLinkModalDisclosure = useDisclosure()

  return (
    <SegmentationHeaderWrapper>
      <SegmentationHeaderWrapper.Title>
        Product sets
      </SegmentationHeaderWrapper.Title>

      <AddProductSetModal
        modalButton={
          <Button
            size="sm"
            leadingIcon={{ name: 'PlusSmallIcon' }}
            colorScheme="gray"
          >
            New product set
          </Button>
        }
        onProductSetCreated={(set) => {
          setCreatedProductSet(set)
          csvLinkModalDisclosure.onOpen()
        }}
      />

      <CopyCSVLinkModal
        isOpen={csvLinkModalDisclosure.isOpen}
        onClose={csvLinkModalDisclosure.onClose}
        segmentationSetName={createdProductSet?.name ?? ''}
        segmentationSetId={createdProductSet?.id ?? ''}
      />
    </SegmentationHeaderWrapper>
  )
}
