import { Flex, Td, Tr } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Tag } from 'components/Tag/Tag'
import { Typography } from 'components/Typography'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/formatDate'
import { PRODUCT_SET_LABEL_COLUMN_NAMES_MAP } from '../../../consts'
import { type SegmentationSet } from '../../../graphql/fragments'
import { SegmentationSetContextMenu } from './SegmentationSetContextMenu/SegmentationSetContextMenu'

interface SegmentRowProps {
  segmentationSet: SegmentationSet
}

export const SegmentRow: React.FC<SegmentRowProps> = ({ segmentationSet }) => {
  const navigate = useNavigate()

  return (
    <Tr
      onClick={() =>
        navigate(SEGMENTATIONS_PAGES.PRODUCT_RULES_BY_ID(segmentationSet.id))
      }
      cursor="pointer"
      _hover={{
        backgroundColor: 'gray.50',
      }}
    >
      <Td>
        <Flex alignItems="center" gap={2}>
          <Icon
            size="small"
            name={segmentationSet.iconName}
            color={segmentationSet.iconColor}
          />

          {segmentationSet.name}

          <Tag
            size="sm"
            colorScheme="grey"
            variant="solid"
            label={
              PRODUCT_SET_LABEL_COLUMN_NAMES_MAP[
                segmentationSet.labelColumnName
              ] ?? segmentationSet.labelColumnName
            }
          />
        </Flex>
      </Td>
      <Td>
        <Typography>
          {formatDate(new Date(segmentationSet.updatedAt))}
        </Typography>
      </Td>
      <Td>
        <SegmentationSetContextMenu segmentationSet={segmentationSet} />
      </Td>
    </Tr>
  )
}
