import { type DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import { Box, Flex, MenuButton } from '@chakra-ui/react'
import { DatePreview } from 'components/Datepicker/DatePreview'
import { Dropdown } from 'components/Dropdown'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type SegmentationSet } from 'features/segmentations/graphql/fragments'
import { SEGMENTATIONS_PAGES } from 'features/segmentations/routePages'
import { SegmentationHeaderWrapper } from 'features/segmentations/shared/SegmentationHeaderWrapper'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { PRODUCT_SET_DATE_RANGES } from '../../../consts'
import { SegmentationSetContextMenu } from '../../ProductSetsView/components/SegmentationSetContextMenu/SegmentationSetContextMenu'

interface SegmentRuleHeaderProps {
  segmentationSet: SegmentationSet
  selectedDatePresetId: DYNAMIC_DATE_ID
  setSelectedDatePresetId: (datePresetId: DYNAMIC_DATE_ID) => void
}

export const SegmentRuleHeader: React.FC<SegmentRuleHeaderProps> = ({
  segmentationSet,
  selectedDatePresetId,
  setSelectedDatePresetId,
}) => {
  const selectedDatePreset = getDatePreset(selectedDatePresetId)
  const [trackEvent] = useTrackEvent()
  const navigate = useNavigate()

  return (
    <SegmentationHeaderWrapper>
      <Flex alignItems="center">
        <Box
          as={Link}
          to={SEGMENTATIONS_PAGES.PRODUCT_SETS}
          _hover={{ textDecoration: 'underline' }}
        >
          <SegmentationHeaderWrapper.Title>
            Product sets
          </SegmentationHeaderWrapper.Title>
        </Box>

        <Typography color="gray.600" fontSize="sm" ml={2} mr={1}>
          /
        </Typography>

        <Flex alignItems="center" gap={2}>
          <Icon
            name={segmentationSet.iconName}
            color={segmentationSet.iconColor}
            size="small"
          />
          <Typography fontSize="medium" fontWeight={500} color="gray.900">
            {segmentationSet.name}
          </Typography>

          <SegmentationSetContextMenu
            segmentationSet={segmentationSet}
            placement="bottom-start"
            onDelete={() => {
              navigate(SEGMENTATIONS_PAGES.PRODUCT_SETS)
            }}
          />
        </Flex>
      </Flex>

      <Box>
        <Dropdown
          customMenuButton={
            <MenuButton
              as={DatePreview}
              actualDate={selectedDatePreset.value}
              badgeLabel={selectedDatePreset.name}
            />
          }
          size="sm"
          matchWidth={false}
          placement="bottom-end"
          options={PRODUCT_SET_DATE_RANGES.map((dateRange) => ({
            id: dateRange,
            name: getDatePreset(dateRange).name,
          }))}
          value={selectedDatePresetId}
          callback={(value) => {
            setSelectedDatePresetId(value.id)
            trackEvent({
              eventName: 'Segmentations Product Set Date Preset Changes',
              eventProperties: {
                segmentationSetId: segmentationSet.id,
                segmentationSetName: segmentationSet.name,
                datePresetId: value.id,
              },
            })
          }}
          renderInPortal={false}
        />
      </Box>
    </SegmentationHeaderWrapper>
  )
}
