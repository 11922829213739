import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import {
  getMetricKeyWithoutAttribution,
  normalizedMetricsAtom,
} from 'graphql/statistics/useMetrics'
import { atom, useAtomValue, useSetAtom, type SetStateAction } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'
import {
  getAnalyticsConfigFromStore,
  getUniqueMetrics,
  getValidMetrics,
} from '../utils/utils'
import { dimensionsStateAtom } from './useDimensionsState'
import { useIsNewReportFlow } from './useIsNewReportFlow'

const focusMetricsStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('metrics'),
)

export const metricsStateAtom = atom(
  (get) => {
    const metrics = get(focusMetricsStateAtom)
    const normalizedMetrics = get(normalizedMetricsAtom)
    const { dimensions } = get(dimensionsStateAtom)

    const validMetrics = getValidMetrics(metrics, normalizedMetrics, dimensions)
    const uniqueMetrics = getUniqueMetrics(validMetrics)
    const metricKeys = validMetrics.map((m) => m.key)

    return {
      metricKeys,
      metrics: validMetrics,
      uniqueMetrics,
      uniqueMetricKeys: uniqueMetrics.map(getMetricKeyWithoutAttribution),
      unsafe_allMetricsKeys: metrics, // could involve invalid metrics
    }
  },
  (_, set, metricsState: SetStateAction<string[]>) => {
    set(focusMetricsStateAtom, metricsState)
  },
)

export const useMetricsState = () => useAtomValue(metricsStateAtom)

export const useSetMetricsState = () => {
  const setMetricsStateAtom = useSetAtom(metricsStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setMetricsState = useCallback(
    (payload: SetStateAction<string[]>) => {
      setMetricsStateAtom(payload)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        if (typeof payload === 'function') {
          newAnalyticsConfig.metrics = payload(newAnalyticsConfig.metrics)
        } else {
          newAnalyticsConfig.metrics = payload
        }
        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setMetricsStateAtom],
  )

  return setMetricsState
}
