import { Box, Divider, Grid } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { EXPERIMENT_METRIC_IDS } from 'features/geoLift/shared/ExperimentConfigDrawer/ExperimentConfigDrawer'
import {
  type InferenceSummaryFieldsFragment,
  type ExperimentSetupFieldsFragment,
} from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { formatMetric } from 'utils/numberFormats'
import { DetailRow } from './ExperimentConfigPanel/DetailRow'

interface ExperimentStatisticsProps {
  setup: ExperimentSetupFieldsFragment
  summary: InferenceSummaryFieldsFragment | undefined
  targetVariable: string
}

export const ExperimentStatistics: React.FC<ExperimentStatisticsProps> = ({
  setup,
  summary,
  targetVariable,
}) => {
  const { currency } = useMerchantInfo()
  const normalizedMetrics = useNormalizedMetrics()

  const hasSummary = summary !== undefined

  const targetVariableLabel = (
    normalizedMetrics[targetVariable]?.label ?? targetVariable
  ).toLowerCase()

  return (
    <Box py={4} px={6}>
      <Typography
        fontSize="sm"
        fontWeight={500}
        lineHeight={5}
        color="gray.800"
        mb={4}
      >
        Experiment statistics
      </Typography>

      <Grid templateColumns="1fr auto 1fr" gap={10}>
        <Box>
          <DetailRow
            label={
              hasSummary
                ? 'Spend during experiment'
                : 'Estimated total spend in treatment group'
            }
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              hasSummary
                ? summary.totalSpend
                : setup.channelMetrics.estimatedTotalSpend,
              currency,
            )}
          />
          <DetailRow
            label="Total spend previous period"
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              setup.channelMetrics.estimatedTotalSpendPastPeriod,
              currency,
            )}
            tooltip={
              'Total spend in the period before the experiment (matching the length of the experiment treatment period) for the selected channel and funnels.'
            }
          />
          <DetailRow
            label={
              hasSummary
                ? `Incremental ${targetVariableLabel}`
                : `Estimated incremental ${targetVariableLabel}`
            }
            value={formatMetric(
              METRIC_FORMAT.CURRENCY,
              hasSummary
                ? summary.totalTargetIncremental
                : setup.channelMetrics.estimatedTotalTargetIncremental,
              currency,
            )}
            tooltip={
              hasSummary
                ? 'Total difference between treatment and control during the experiment.'
                : 'Estimated total difference between treatment and control by the end of the experiment.'
            }
          />

          {hasSummary && (
            <DetailRow
              label={`Incremental ${targetVariable === EXPERIMENT_METRIC_IDS.ORDER_TOTAL ? 'ROAS' : 'epROAS'}`}
              value={formatMetric(
                METRIC_FORMAT.PERCENT,
                summary.incrementalFactor,
              )}
            />
          )}
        </Box>

        <Divider orientation="vertical" />

        <Box>
          <DetailRow
            label={`Treatment group proportion of ${targetVariableLabel}`}
            value={formatMetric(
              METRIC_FORMAT.PERCENT_INT,
              setup.treatmentProportion,
            )}
          />
          <DetailRow
            label={
              hasSummary
                ? 'Lift detected due to marketing'
                : 'Estimated lift due to marketing'
            }
            value={formatMetric(
              METRIC_FORMAT.PERCENT,
              hasSummary ? summary.lift : setup.channelMetrics.estimatedLift,
            )}
          />
          {!hasSummary && (
            <DetailRow
              label="Minimum detectable effect"
              value={formatMetric(
                METRIC_FORMAT.PERCENT,
                setup.avgMinimumDetectableEffect,
              )}
              tooltip={
                'The smallest change in the treatment group’s performance that can be reliably detected by the test given its design.'
              }
            />
          )}
          <DetailRow
            label="Treatment group correlation"
            value={formatMetric(METRIC_FORMAT.PERCENT, setup.correlation)}
            tooltip={
              'How well the treatment and control groups match historically. Higher correlation (>90%) indicates well-matched groups for reliable comparison.'
            }
          />

          {hasSummary && (
            <DetailRow
              label="P-value"
              value={formatMetric(METRIC_FORMAT.PERCENT, summary.pValue)}
              tooltip={
                'The probability of observing the data if the null hypothesis is true. The null hypothesis is that there is no difference between the treatment and control groups.'
              }
            />
          )}
        </Box>
      </Grid>
    </Box>
  )
}
