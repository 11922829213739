import { Box, Flex, Progress } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import type React from 'react'

interface ProgressBarProps {
  title?: string
  description?: string
  progress: number | null
  status?: 'error'
  size?: 'small' | 'medium'
}

// Swap this out for chakra ui progress bar once migrated to v3
export const ProgressBar: React.FC<ProgressBarProps> = ({
  title,
  description,
  progress,
  status,
  size = 'medium',
}) => {
  const isError = status === 'error'

  return (
    <Box>
      {title && (
        <Flex justifyContent="space-between">
          <Typography mb={2}>{title}</Typography>
          {isError && (
            <Icon name="ExclamationCircleIcon" size="small" color="red.500" />
          )}
        </Flex>
      )}
      <Progress
        value={progress ?? 10}
        h={size === 'small' ? 1 : 2}
        isIndeterminate={progress === null}
        borderRadius={0}
        colorScheme={isError ? 'red' : undefined}
        bg="gray.300"
      />

      {description && (
        <Typography
          color={isError ? 'red.500' : 'gray.700'}
          fontSize="xs"
          lineHeight={4}
          mt={2}
        >
          {description}
        </Typography>
      )}
    </Box>
  )
}
