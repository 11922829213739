import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { format } from 'date-fns'
import { type ExperimentConfig } from 'features/geoLift/graphql/fragments'
import { useCreateExperiment } from 'features/geoLift/graphql/useCreateExperiment'
import { useDeleteExperiment } from 'features/geoLift/graphql/useDeleteExperiment'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import {
  ExperimentConfigDrawer,
  type ValidationSchema,
} from 'features/geoLift/shared/ExperimentConfigDrawer/ExperimentConfigDrawer'
import { useNavigate } from 'react-router'

interface EditExperimentDrawerProps {
  isOpen: boolean
  onClose: () => void
  config: ExperimentConfig
  experimentId: string
}

export const EditExperimentDrawer: React.FC<EditExperimentDrawerProps> = ({
  isOpen,
  onClose,
  config,
  experimentId,
}) => {
  const { deleteExperiment, loading } = useDeleteExperiment()
  const [createExperimentMutation, createExperimentStatus] =
    useCreateExperiment()

  const navigate = useNavigate()
  const toast = useToast()

  const onSubmit = (args: ValidationSchema) => {
    createExperimentMutation({
      payload: {
        ...args,
        startDate: format(args.startDate, 'yyyy-MM-dd'),
        channelGroup: 'social_ppc',
      },
      options: {
        onCompleted: (data) => {
          deleteExperiment({
            variables: {
              id: experimentId,
            },
            update: (cache, { data }) => {
              if (!data?.deleteExperiment) return

              // Evict both ExperimentSummary and Experiment types from cache
              cache.evict({
                id: cache.identify({
                  ...data.deleteExperiment,
                  __typename: 'ExperimentSummary',
                }),
              })
              cache.evict({
                id: cache.identify({
                  ...data.deleteExperiment,
                  __typename: 'Experiment',
                }),
              })
              cache.gc()
            },
          })
          navigate(
            GEO_LIFT_PAGES.EXPERIMENT_BY_ID(data.createExperiment.config.id),
          )
          onClose()
          toast({ description: 'Successfully updated experiment' })
        },
        onError: (error) => {
          toast({
            description: 'Failed to update experiment',
            status: 'error',
          })
          captureException(`Edit experiment: ${error}`)
        },
      },
    })
  }

  return (
    <ExperimentConfigDrawer
      isOpen={isOpen}
      onClose={onClose}
      defaultValues={{ ...config, startDate: new Date(config.startDate) }}
      mode="edit"
      onSubmit={onSubmit}
      isLoading={loading || createExperimentStatus.loading}
    />
  )
}
