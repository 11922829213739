import { List } from '@chakra-ui/react'
import { ComboBoxItem } from 'components/Dropdown/ComboBox/components/ComboBoxItem'

interface Props {
  groups: { id: string; name: string }[]
  selectedGroup: string
  setSelectedGroup: (group: string) => void
}
export const GroupsList = ({
  groups,
  selectedGroup,
  setSelectedGroup,
}: Props) => {
  return (
    <List variant="menu" py={2} px={4}>
      {groups.map((group) => (
        <ComboBoxItem
          key={group.id}
          option={{ id: group.id, name: group.name }}
          onClick={() => setSelectedGroup(group.id)}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              setSelectedGroup(group.id)
            }
          }}
          isSelected={selectedGroup === group.id}
          tabIndex={0}
          role="option"
        />
      ))}
    </List>
  )
}
