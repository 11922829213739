import {
  Menu,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { filterOptions, type ExperimentOptionFields } from './consts'
import { ExperimentsFilterButton } from './ExperimentsFilterButton'

interface Props {
  setFilterOption: (option: ExperimentOptionFields | undefined) => void
}
export const ExperimentsFilterMenu = ({ setFilterOption }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={() => {
        setFilterOption(undefined)
        onClose()
      }}
      size="sm"
      variant="outline"
      gutter={2}
      isLazy={true}
      closeOnSelect={false}
    >
      <ExperimentsFilterButton />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList>
          {filterOptions.map(({ id, name }) => (
            <MenuItem key={id} onClick={() => setFilterOption(id)}>
              {name}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
}
