import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { useState } from 'react'
import { type ExperimentFilters, type ExperimentOptionFields } from './consts'
import { ExperimentsFilterButton } from './ExperimentsFilterButton'
import { ExperimentsFilterMenu } from './ExperimentsFilterMenu'
import { useUniqueFilterOptions } from './useUniqueFilterOptions'

interface ExperimentsFilterPickerProps {
  filters: ExperimentFilters
  experiments: ExperimentSummary[]
  setFilters: (option: ExperimentOptionFields, values: string[]) => void
}

export const ExperimentsFilterPicker = ({
  filters,
  experiments,
  setFilters,
}: ExperimentsFilterPickerProps) => {
  const [filterOption, setFilterOption] = useState<
    ExperimentOptionFields | undefined
  >(undefined)

  if (!filterOption) {
    return <ExperimentsFilterMenu setFilterOption={setFilterOption} />
  }

  const filterValues = filters[filterOption]

  return (
    <ExperimentsFilterOption
      setFilterOption={setFilterOption}
      filterValues={filterValues}
      experiments={experiments}
      filterOption={filterOption}
      setFilters={setFilters}
    />
  )
}

interface ExperimentsFilterOptionProps {
  filterValues: string[]
  experiments: ExperimentSummary[]
  filterOption: ExperimentOptionFields
  setFilterOption: (option: ExperimentOptionFields | undefined) => void
  setFilters: (option: ExperimentOptionFields, values: string[]) => void
}

const ExperimentsFilterOption = ({
  filterValues,
  experiments,
  filterOption,
  setFilterOption,
  setFilters,
}: ExperimentsFilterOptionProps) => {
  const uniqueOptions = useUniqueFilterOptions(experiments, filterOption)

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      isOpenByDefault
      customMenuButton={<ExperimentsFilterButton />}
      options={uniqueOptions}
      selected={filterValues}
      setSelected={(selected) => setFilters(filterOption, selected)}
      onClose={() => setFilterOption(undefined)}
    />
  )
}
