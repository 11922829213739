import {
  format,
  isSameDay as isSameDayFn,
  isSameMonth as isSameMonthFn,
  isSameYear as isSameYearFn,
} from 'date-fns'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat, type TimeUnit } from 'graphql/statistics/types'
import { type Dimension } from 'graphql/statistics/useDimensions'
import { type Metric } from 'graphql/statistics/useMetrics'
import Highcharts from 'highcharts'
import { formatMetricLabel } from 'utils/formatMetricLabel'
import { formatPercentage, formatMetric } from 'utils/numberFormats'
import { CHART_TYPE_ID, type ChartTypeId, chartTypes } from './chartTypes'
import { AXIS_TYPE, isTimeUnit, normalizedTimeDimensions } from './constants'

export const getXAxisDateFormat = (xAxis: TimeUnit, value: number) => {
  switch (xAxis) {
    case 'hour':
      return Highcharts.dateFormat('%H:%M', value)
    case 'day':
      return Highcharts.dateFormat('%d %b %y', value)
    case 'week':
      return Highcharts.dateFormat('Week %W', value)
    case 'month':
      return Highcharts.dateFormat('%b %y', value)
    case 'quarter':
      return Highcharts.dateFormat('%b %y', value)
    case 'year':
      return Highcharts.dateFormat('%Y', value)
    default:
      return value
  }
}

const dateTimeLabelFormats: Partial<Record<TimeUnit, string | undefined>> = {
  hour: '%H:%M',
  day: '%d %b',
  week: 'Week %W',
  month: '%b %y',
  quarter: 'Q%Q',
  year: '%Y',
} as const

const defaultDateTimeFormat = dateTimeLabelFormats.month ?? '%b %y'

export const getDateTimeLabelFormats = (xAxis: TimeUnit) => {
  const value: string | undefined =
    xAxis in dateTimeLabelFormats ? dateTimeLabelFormats[xAxis] : undefined

  if (value) {
    return {
      hour: value,
      day: value,
      week: value,
      month: value,
      year: value,
    }
  }
}

export const sortByNumberOrString = (
  first: string | number,
  second: string | number,
) => {
  if (isFinite(Number(second)) && isFinite(Number(first))) {
    // Numerical order descending
    return Number(second) - Number(first)
  }

  // Alphabetical order
  return String(first).localeCompare(String(second))
}

enum DateTimeFormat {
  HOUR_FORMAT = 'HH:mm',
  DAY_FORMAT = 'dd',
  MONTH_FORMAT = 'MMM',
  YEAR_FORMAT = 'yyyy',
}

interface FormatDateTimeArgs {
  start: number | Date
  end: number | Date
  isPerHour: boolean
}

export const formatDateTime = ({
  start,
  end,
  isPerHour,
}: FormatDateTimeArgs) => {
  const isSameDay = isSameDayFn(start, end)
  const isSameMonth = isSameMonthFn(start, end)
  const isSameYear = isSameYearFn(start, end)

  const formattedStartHour = format(start, DateTimeFormat.HOUR_FORMAT)
  const formattedEndHour = format(end, DateTimeFormat.HOUR_FORMAT)
  const formattedStartDay = format(start, DateTimeFormat.DAY_FORMAT)
  const formattedEndDay = format(end, DateTimeFormat.DAY_FORMAT)
  const formattedStartMonth = format(start, DateTimeFormat.MONTH_FORMAT)
  const formattedEndMonth = format(end, DateTimeFormat.MONTH_FORMAT)
  const formattedStartYear = format(start, DateTimeFormat.YEAR_FORMAT)
  const formattedEndYear = format(end, DateTimeFormat.YEAR_FORMAT)

  if (isPerHour) {
    return `${formattedStartHour} - ${formattedEndHour}, ${formattedStartDay} ${formattedStartMonth}, ${formattedStartYear}`
  }

  if (!isSameYear) {
    return `${formattedStartDay} ${formattedStartMonth}, ${formattedStartYear} - ${formattedEndDay} ${formattedEndMonth}, ${formattedEndYear}`
  }

  if (!isSameMonth) {
    return `${formattedStartDay} ${formattedStartMonth} - ${formattedEndDay} ${formattedEndMonth}, ${formattedStartYear}`
  }

  // there is an edge case where start date is before end date
  if (!isSameDay && end > start) {
    return `${formattedStartDay} - ${formattedEndDay} ${formattedStartMonth}, ${formattedStartYear}`
  }

  return `${formattedStartDay} ${formattedStartMonth}, ${formattedStartYear}`
}

export const getAxisType = (
  xAxis: string,
  isMetric: boolean,
  hasColumnSeries?: boolean,
): Highcharts.AxisTypeValue => {
  // Column series should always be category
  if (isTimeUnit(xAxis) && !hasColumnSeries) {
    return AXIS_TYPE.DATETIME
  }
  if (isMetric) {
    return AXIS_TYPE.LINEAR
  }

  return AXIS_TYPE.CATEGORY
}

export const getXAxisLabel = (
  xAxis: string,
  gType: ChartTypeId | undefined,
  dimension?: Dimension,
  metric?: Metric,
  currency?: string,
) => {
  if (isTimeUnit(xAxis)) {
    return normalizedTimeDimensions[xAxis].name
  }
  if (gType && chartTypes[gType].type === CHART_TYPE_ID.SCATTER) {
    return dimension?.label ?? formatMetricLabel(metric, currency)
  }

  return dimension?.label
}

export const getXAxisValue = (xAxisType: string, value: string | number) => {
  if (xAxisType === AXIS_TYPE.DATETIME || xAxisType === AXIS_TYPE.LINEAR) {
    return Number(value)
  }

  return value
}

export const yAxisFormatter = (metricFormat?: MetricFormat) =>
  function (this: Highcharts.AxisLabelsFormatterContextObject) {
    const getFormattedYValue = () => {
      const valueAsNumber = Number(this.value)

      if (!metricFormat || isNaN(valueAsNumber)) {
        return ''
      }

      if (METRIC_FORMAT.PERCENT === metricFormat) {
        const maxValue = (this.axis.max ?? 0.1) * 100
        const numberOfDecimals = maxValue < 10 ? 1 : 0

        return formatPercentage(valueAsNumber, numberOfDecimals)
      } else if (this.axis.max && this.axis.max < 10) {
        return formatMetric(METRIC_FORMAT.ABBREVIATED_DECIMAL, valueAsNumber)
      }

      const format =
        METRIC_FORMAT.SECONDS === metricFormat
          ? metricFormat
          : METRIC_FORMAT.ABBREVIATED_INT

      return formatMetric(format, valueAsNumber)
    }

    return getFormattedYValue()
  }

export const xAxisFormatter = (xAxis: string, metric?: Metric) => {
  return function (this: Highcharts.AxisLabelsFormatterContextObject) {
    const xValue = Number(this.value)

    if (!isNaN(xValue)) {
      if (isTimeUnit(xAxis)) {
        const format = dateTimeLabelFormats[xAxis] ?? defaultDateTimeFormat

        return Highcharts.dateFormat(format, xValue)
      }

      const metricFormat = metric?.format

      if (metricFormat) {
        const format =
          metricFormat === METRIC_FORMAT.PERCENT
            ? metricFormat
            : METRIC_FORMAT.ABBREVIATED_INT

        return formatMetric(format, xValue)
      }
    }

    return this.value
  }
}
