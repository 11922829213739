import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { format } from 'date-fns'
import { ExperimentConfigPurpose } from 'features/geoLift/graphql/fragments'
import { useCreateExperiment } from 'features/geoLift/graphql/useCreateExperiment'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import {
  ExperimentConfigDrawer,
  type ValidationSchema,
} from 'features/geoLift/shared/ExperimentConfigDrawer/ExperimentConfigDrawer'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useNavigate } from 'react-router'

interface CreateExperimentDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateExperimentDrawer: React.FC<CreateExperimentDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { sites = [] } = useMerchantInfo()
  const [createExperimentMutation, createExperimentStatus] =
    useCreateExperiment()

  const navigate = useNavigate()
  const toast = useToast()

  const onSubmit = (args: ValidationSchema) => {
    createExperimentMutation({
      payload: {
        ...args,
        startDate: format(args.startDate, 'yyyy-MM-dd'),
        channelGroup: 'social_ppc',
      },
      options: {
        onCompleted: (data) => {
          navigate(
            GEO_LIFT_PAGES.EXPERIMENT_BY_ID(data.createExperiment.config.id),
          )

          toast({ description: 'Successfully created experiment' })
        },
        onError: (error) => {
          toast({ description: 'Failed to create experiment', status: 'error' })
          captureException(`Create experiment: ${error}`)
        },
      },
    })
  }

  const defaultValues: ValidationSchema = {
    name: '',
    frontendId: sites[0].frontendId,
    country: '',
    purpose: ExperimentConfigPurpose.LIFT,
    channel: '',
    funnelCampaigns: [],
    targetVariable: '',
    targetReturnOnSpend: NaN,
    startDate: null as unknown as Date,
    treatmentPeriod: 0,
    postTreatmentPeriod: 0,
  }

  return (
    <ExperimentConfigDrawer
      isOpen={isOpen}
      onClose={onClose}
      defaultValues={defaultValues}
      mode="create"
      onSubmit={onSubmit}
      isLoading={createExperimentStatus.loading}
    />
  )
}
