import {
  Box,
  ListItem as ChakraListItem,
  forwardRef,
  type ListItemProps,
} from '@chakra-ui/react'
import { mergeRefs } from '@chakra-ui/react-utils'
import { type useCombobox } from 'downshift'
import { type ForwardedRef, type ReactElement } from 'react'
import { MenuItemText } from '../../components/MenuItemText'
import { type DropdownOption } from '../../types'

interface Props<TItem extends DropdownOption> extends ListItemProps {
  option: TItem
  isSelected?: boolean
  isHighlighted?: boolean
  itemProps?: ReturnType<ReturnType<typeof useCombobox>['getItemProps']>
  itemStyles?: ListItemProps
  isOption?: boolean
  leftItem?: ReactElement
  rightItem?: ReactElement
  onMouseEnter?: () => void
}

export const ComboBoxItem = forwardRef(
  <TItem extends DropdownOption>(
    {
      leftItem,
      rightItem,
      isSelected,
      isHighlighted,
      option,
      itemProps,
      itemStyles,
      isOption,
      onMouseEnter,
      ...rest // This is needed for using some HOC which adds additional props. For example, using Chakra's Tooltip in itemWrapper.
    }: Props<TItem>,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const itemRef = itemProps?.ref ? mergeRefs(ref, itemProps.ref) : ref

    return (
      // This is needed for the onMouseEnter to work even when the item is disabled
      <Box onMouseEnter={onMouseEnter}>
        <ChakraListItem
          alignItems="center"
          aria-disabled={option.disabled}
          aria-selected={isSelected}
          bg={isHighlighted ? 'grey.100' : ''}
          cursor="pointer"
          display="flex"
          _hover={{
            bg: 'grey.100',
          }}
          _selected={{
            bg: isHighlighted ? 'grey.300' : 'grey.200',
            '&:hover': {
              bg: 'grey.300',
            },
          }}
          _focusVisible={{
            boxShadow: 'var(--chakra-shadows-outline)',
            outline: 'none',
          }}
          value={option.id.toString()}
          // Order matters here. Spreading rest before itemProps can make Downshift to stop working and make the component to stop working.
          {...rest}
          {...itemProps}
          {...itemStyles}
          ref={itemRef}
        >
          <MenuItemText
            option={option}
            leftItem={leftItem}
            isDisabled={option.disabled}
            rightItem={rightItem}
            isOption={isOption}
            isSelected={isSelected}
          />
        </ChakraListItem>
      </Box>
    )
  },
)

ComboBoxItem.displayName = 'ComboBoxItem'
