import { Box } from '@chakra-ui/react'
import type React from 'react'
import { ProductSetsHeader } from './components/ProductSetsHeader'
import { ProductSetsList } from './components/ProductSetsList'

export const ProductSetsView: React.FC = () => {
  return (
    <Box>
      <ProductSetsHeader />
      <ProductSetsList />
    </Box>
  )
}
