import { Box, Flex } from '@chakra-ui/react'
import { useOrganization, useUser } from '@clerk/clerk-react'
import { createColumnHelper } from '@tanstack/react-table'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography/Typography'
import { differenceInYears, format, formatDistanceToNow } from 'date-fns'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { ReportFavoriteStar } from 'features/reports/components/ReportFavoriteStar'
import { ReportContextualMenu } from 'features/reports/components/ReportSidebar/ReportContextualMenu/ReportContextualMenu'
import { ReportVisibility } from 'features/reports/views/shared/ReportVisibility/ReportVisibility'
import {
  REPORT_VISIBILITY,
  REPORT_VISIBILITY_SORT_ORDER,
  getReportOwnerName,
  getReportVisibilityType,
} from 'graphql/reports/utils'
import { useMerchantTeams } from 'graphql/teams/useMerchantTeams'
import { useMemo } from 'react'
import { chartTypes } from 'utils/chart/chartTypes'
import { ReportListLabelsCell } from './ReportListLabelsCell'
import { ReportListOwnerCell } from './ReportOwner/ReportListOwnerCell'
import { useEditReportVisibility } from './ReportVisibility/useEditReportVisibility'

const columnHelper = createColumnHelper<ReportState>()

export const useReportListColumns = () => {
  const { editReportVisibility } = useEditReportVisibility()

  const { merchantTeams, query: merchantTeamsQuery } = useMerchantTeams()
  const { user } = useUser()
  const userId = String(user?.publicMetadata.dema_id)
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: ({ row }) => {
          const report = row.original
          const { name, description } = report
          const icon =
            chartTypes[report.analyticsConfig.chart.series[0].type]
              .graphIconName

          return (
            <Flex gap={2}>
              <Icon name={icon} size="large" />
              <Box>
                <Flex alignItems="center" gap={2}>
                  <Typography
                    fontSize="sm"
                    fontWeight={600}
                    lineHeight={5}
                    noOfLines={1}
                    title={name}
                  >
                    {name}
                  </Typography>
                  <ReportFavoriteStar report={report} />
                </Flex>
                {description && (
                  <Typography
                    fontSize="xs"
                    lineHeight={4}
                    color="grey.700"
                    noOfLines={1}
                    title={description}
                  >
                    {description}
                  </Typography>
                )}
              </Box>
            </Flex>
          )
        },
        header: 'Name',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
      }),
      columnHelper.accessor('owner', {
        cell: ({ row, getValue }) => {
          const owner = getValue()

          return (
            <ReportListOwnerCell
              report={row.original}
              owner={owner}
              membershipsData={memberships?.data}
            />
          )
        },
        header: 'Owner',
        sortingFn: (a, b) => {
          // This is not performant at all since it's recalculating the name for each row and calculating the name has to traverse the whole memberships array.
          const aName = getReportOwnerName({
            owner: a.original.owner,
            memberships: memberships?.data,
          })
          const bName = getReportOwnerName({
            owner: b.original.owner,
            memberships: memberships?.data,
          })

          return aName.localeCompare(bName)
        },
      }),
      columnHelper.accessor('visibility', {
        cell: ({ row }) => (
          <Box position="relative" left={-2}>
            <ReportVisibility
              key={getReportVisibilityType(row.original.visibility)} // This is to reset the state when the visibility changes
              report={row.original}
              teamOptions={merchantTeams}
              isLoading={merchantTeamsQuery.loading}
              onVisibilityChanged={(newVisibility) =>
                editReportVisibility({
                  selectedReport: row.original,
                  newVisibility,
                  oldVisibility:
                    row.original.visibility?.map((v) => v.id) ?? [],
                })
              }
            />
          </Box>
        ),
        sortingFn: (rowA, rowB) => {
          const rowAVisibilityType = getReportVisibilityType(
            rowA.original.visibility,
          )
          const rowBVisibilityType = getReportVisibilityType(
            rowB.original.visibility,
          )
          const rowAOrder = REPORT_VISIBILITY_SORT_ORDER[rowAVisibilityType]
          const rowBOrder = REPORT_VISIBILITY_SORT_ORDER[rowBVisibilityType]

          // Check if they have different visibility
          if (rowAOrder < rowBOrder) {
            return -1
          }
          if (rowAOrder > rowBOrder) {
            return 1
          }

          // If both are teams, compare by first team name
          if (
            rowAVisibilityType === REPORT_VISIBILITY.TEAM &&
            rowBVisibilityType === REPORT_VISIBILITY.TEAM
          ) {
            const rowAName = rowA.original.visibility?.[0]?.name ?? ''
            const rowBName = rowB.original.visibility?.[0]?.name ?? ''

            return rowAName.localeCompare(rowBName)
          }

          // Same visibility, and not team
          return 0
        },
        header: 'Visibility',
      }),
      columnHelper.accessor('updatedAt', {
        cell: ({ getValue }) => {
          const updatedAt = getValue()

          if (!updatedAt) return null

          const now = new Date(updatedAt)
          const formattedTooltip = format(now, 'yyyy-MM-dd')
          const diffYears = differenceInYears(now, new Date())
          const isWithinOneYear = diffYears < 1
          const lastUpdated = isWithinOneYear
            ? formatDistanceToNow(now, { addSuffix: true })
            : formattedTooltip

          return (
            <Typography title={isWithinOneYear ? formattedTooltip : undefined}>
              {lastUpdated}
            </Typography>
          )
        },
        header: 'Last updated',
        sortingFn: 'alphanumeric',
      }),
      columnHelper.display({
        id: 'labels',
        cell: ({ row }) => <ReportListLabelsCell report={row.original} />,
      }),
      columnHelper.display({
        id: 'settings',
        cell: ({ row }) => {
          const report = row.original

          if (!report) return null

          return <ReportContextualMenu report={report} userId={userId} />
        },
      }),
    ],
    [
      memberships?.data,
      merchantTeams,
      merchantTeamsQuery.loading,
      editReportVisibility,
      userId,
    ],
  )

  return columns
}
