import { Box } from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import { useIsNewReportFlow } from 'features/reports/hooks/useIsNewReportFlow'
import {
  useReportStateAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { ReportListOwnerCell } from 'features/reports/views/shared/ReportOwner/ReportListOwnerCell'

import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

export const OwnerSection = () => {
  const isNewReportFlow = useIsNewReportFlow()
  const selectedReport = useReportStateAtom()
  const setReportState = useSetReportStateAtom()
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  return (
    <Box>
      <ReportDetailsHeader title="Owner" />

      <ReportListOwnerCell
        report={selectedReport}
        owner={selectedReport?.owner}
        membershipsData={memberships?.data}
        isNewReportFlow={isNewReportFlow}
        onOwnerChanged={(userId) => {
          setReportState((prev) =>
            prev
              ? { ...prev, owner: { externalId: userId, id: 'optimistic' } }
              : prev,
          )
        }}
      />
    </Box>
  )
}
