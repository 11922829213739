import {
  useMutation,
  type ApolloCache,
  type MutationFunctionOptions,
} from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type ExperimentSummaryFieldsFragment,
  type CreateExperimentInput,
  type CreateExperimentMutation,
  type ExperimentFieldsFragment,
} from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { cloneDeep, set } from 'lodash-es'
import { useCallback } from 'react'
import { ExperimentSummaryFragment } from './fragments'
import { EXPERIMENT_QUERY } from './useExperiment'
import { EXPERIMENTS_QUERY } from './useExperiments'

const CREATE_EXPERIMENT_MUTATION = graphql(/* GraphQL */ `
  mutation CreateExperiment($payload: CreateExperimentInput!) {
    createExperiment(params: $payload) {
      ...ExperimentFields
    }
  }
`)

export const useCreateExperiment = (): [
  typeof createExperiment,
  typeof state,
] => {
  const [mutation, state] = useMutation(CREATE_EXPERIMENT_MUTATION)
  const [trackEvent] = useTrackEvent()
  const createExperiment = useCallback(
    async ({
      payload,
      options,
    }: {
      payload: CreateExperimentInput
      options?: MutationFunctionOptions<CreateExperimentMutation>
    }) => {
      const result = await mutation({
        ...options,
        variables: { payload },
        update: (cache, { data }) => {
          if (!data?.createExperiment) return

          updateExperimentQuery({ cache, experiment: data.createExperiment })
          updateExperimentsQuery({ cache, experiment: data.createExperiment })
        },
        onCompleted: (data) => {
          trackEvent({
            eventName: 'GeoLift Experiment Created',
            eventProperties: {
              experimentId: data.createExperiment.id,
            },
          })

          options?.onCompleted?.(data)
        },
      })

      return result
    },
    [mutation, trackEvent],
  )

  return [createExperiment, state]
}

const updateExperimentQuery = ({
  cache,
  experiment,
}: {
  cache: ApolloCache<unknown>
  experiment: ExperimentFieldsFragment
}) => {
  cache.updateQuery(
    { query: EXPERIMENT_QUERY, variables: { id: experiment.id } },
    (queryData) => {
      if (!queryData?.viewer?.merchant) return queryData

      const queryDataClone = cloneDeep(queryData)

      set(queryDataClone, 'viewer.merchant.experiment', experiment)

      return queryDataClone
    },
  )
}

const updateExperimentsQuery = ({
  cache,
  experiment,
}: {
  cache: ApolloCache<unknown>
  experiment: ExperimentFieldsFragment
}) => {
  const experimentSummary: ExperimentSummaryFieldsFragment = {
    id: experiment.id,
    status: experiment.status,
    name: experiment.config.name,
    purpose: experiment.config.purpose,
    frontendId: experiment.config.frontendId,
    startDate: experiment.config.startDate,
    endDate: experiment.config.endDate,
    channel: experiment.config.channel,
    country: experiment.config.country,
    targetVariable: experiment.config.targetVariable,
    __typename: 'ExperimentSummary',
  }

  cache.writeFragment({
    id: cache.identify(experimentSummary),
    data: experimentSummary,
    fragment: ExperimentSummaryFragment,
    fragmentName: 'ExperimentSummaryFields',
  })
  cache.updateQuery({ query: EXPERIMENTS_QUERY }, (queryData) => {
    if (!queryData?.viewer?.merchant) return queryData

    const { experiments } = queryData.viewer.merchant
    const clonedQueryData = cloneDeep(queryData)

    set(clonedQueryData, 'viewer.merchant.experiments', [
      ...experiments,
      experimentSummary,
    ])

    return clonedQueryData
  })
}
