import { Divider, Grid } from '@chakra-ui/react'
import {
  getFilteredOptions,
  isGroupOption,
  sortOptions,
} from 'components/Dropdown/ComboBox/utils'
import { useDraftDimensions } from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import { useDimensionsState } from 'features/reports/hooks/useDimensionsState'
import { ORGANIZATION_ID } from 'graphql/reports/utils'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useUserTeams } from 'graphql/teams/useUserTeams'
import { useBreakpoint } from 'hooks/useBreakpoint'
import { useDimensionGroups } from 'hooks/useDimensionGroups'
import { groupBy } from 'lodash-es'
import { useMemo, useState } from 'react'
import { GroupsList } from './GroupsList'
import { ItemsList } from './ItemsList'
import { SearchInput } from './SearchInput'

const ALL_DIMENSIONS_GROUP = 'All dimensions'

export const DimensionSelectorSection = () => {
  const [isLargerThanSm] = useBreakpoint('sm')
  const [selectedTeam, setSelectedTeam] = useState(ORGANIZATION_ID)
  const [selectedGroup, setSelectedGroup] =
    useState<string>(ALL_DIMENSIONS_GROUP)
  const [inputValue, setInputValue] = useState('')
  const normalizedDimensions = useNormalizedDimensions()
  const { dimensionKeys } = useDimensionsState()
  const [draftSelected, setDraftSelected] = useDraftDimensions()

  const { userTeams } = useUserTeams()
  const teamsWithPreferredData = useMemo(
    () => userTeams.filter((team) => team.preferredDimensions.length > 0),
    [userTeams],
  )
  const hasTeamsWithPreferredData = teamsWithPreferredData.length > 0

  const filteredByTeamDimensions = useMemo(() => {
    const allDimensions = Object.values(normalizedDimensions)

    if (selectedTeam === ORGANIZATION_ID || !hasTeamsWithPreferredData) {
      return allDimensions
    }

    const preferredDimensions = teamsWithPreferredData.find(
      (team) => team.id === selectedTeam,
    )?.preferredDimensions

    return allDimensions.filter((dim) => {
      return preferredDimensions?.includes(dim.id)
    })
  }, [
    normalizedDimensions,
    selectedTeam,
    teamsWithPreferredData,
    hasTeamsWithPreferredData,
  ])

  const groupedDimensions = useDimensionGroups({
    dimensions: filteredByTeamDimensions,
  })
  const groupedSelectedDimensions = useMemo(
    () =>
      groupBy(
        draftSelected.map((id) => normalizedDimensions[id]),
        'groupLabel',
      ),
    [draftSelected, normalizedDimensions],
  )

  const dimensionGroups = useMemo(
    () => [
      {
        id: ALL_DIMENSIONS_GROUP,
        name: `${ALL_DIMENSIONS_GROUP} ${draftSelected.length ? `(${draftSelected.length})` : ''}`,
      },
      ...groupedDimensions.map((group) => ({
        id: group.name,
        name: `${group.name} ${
          groupedSelectedDimensions[group.name]?.length
            ? `(${groupedSelectedDimensions[group.name]?.length})`
            : ''
        }`,
      })),
    ],
    [groupedDimensions, groupedSelectedDimensions, draftSelected.length],
  )

  const groupedDimensionsOnGroup = useMemo(() => {
    if (selectedGroup === ALL_DIMENSIONS_GROUP) {
      return groupedDimensions
    }

    return groupedDimensions.filter((group) => {
      return group.name === selectedGroup
    })
  }, [groupedDimensions, selectedGroup])

  const sortedOptions = useMemo(
    () =>
      sortOptions(
        groupedDimensionsOnGroup,
        dimensionKeys,
        selectedGroup === ALL_DIMENSIONS_GROUP,
      ),
    [groupedDimensionsOnGroup, dimensionKeys, selectedGroup],
  )
  const filteredOptions = useMemo(() => {
    return getFilteredOptions(sortedOptions, inputValue).filter(isGroupOption)
  }, [sortedOptions, inputValue])

  return (
    <Grid gridTemplateRows="auto 1fr">
      <SearchInput
        teams={teamsWithPreferredData}
        selectedTeam={selectedTeam}
        onTeamChange={(teamId) => {
          setSelectedTeam(teamId)
          setSelectedGroup(ALL_DIMENSIONS_GROUP)
          setInputValue('')
        }}
        inputValue={inputValue}
        setInputValue={setInputValue}
        shouldShowTeamToggle={hasTeamsWithPreferredData && isLargerThanSm}
        placeholder="Search dimensions"
      />
      <Grid gridTemplateColumns={['1fr', '240px auto 1fr']}>
        {isLargerThanSm && (
          <>
            <GroupsList
              groups={dimensionGroups}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
            <Divider orientation="vertical" />
          </>
        )}
        <ItemsList
          filteredOptions={filteredOptions}
          draftSelected={draftSelected}
          setDraftSelected={setDraftSelected}
        />
      </Grid>
    </Grid>
  )
}
