import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { getChannelName } from 'features/optimizations/utils/transformChannel'
import { getGridLineOptions } from 'features/reports/utils/chart/common'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import { get } from 'lodash-es'
import type React from 'react'
import { colorTheme } from 'ui/theme/colors'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { staticChartOptions, yAxisZeroPlotLine } from 'utils/chart/constants'
import { baseSaturationChartOptions } from '../baseChart'
import { getTooltipFormatter } from '../shared/getTooltipFormatter'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)

interface MultiChannelGraphProps {
  curveData: SaturationCurveData
}

interface LineColor {
  key: keyof typeof colorTheme
  primaryShade: number
  secondaryShade: number
}
export const lineColors: LineColor[] = [
  { key: 'purple', primaryShade: 500, secondaryShade: 200 },
  { key: 'cyan', primaryShade: 500, secondaryShade: 200 },
  { key: 'magenta', primaryShade: 500, secondaryShade: 200 },
  { key: 'orange', primaryShade: 500, secondaryShade: 200 },
  { key: 'blue', primaryShade: 500, secondaryShade: 200 },
  { key: 'yellow', primaryShade: 500, secondaryShade: 200 },

  { key: 'purple', primaryShade: 700, secondaryShade: 300 },
  { key: 'cyan', primaryShade: 700, secondaryShade: 300 },
  { key: 'magenta', primaryShade: 700, secondaryShade: 200 },
  { key: 'orange', primaryShade: 700, secondaryShade: 200 },
  { key: 'blue', primaryShade: 700, secondaryShade: 200 },
  { key: 'yellow', primaryShade: 700, secondaryShade: 200 },

  { key: 'purple', primaryShade: 400, secondaryShade: 300 },
  { key: 'cyan', primaryShade: 400, secondaryShade: 300 },
  { key: 'magenta', primaryShade: 400, secondaryShade: 200 },
  { key: 'orange', primaryShade: 400, secondaryShade: 200 },
  { key: 'blue', primaryShade: 400, secondaryShade: 200 },
  { key: 'yellow', primaryShade: 400, secondaryShade: 200 },
]

const getLineData = (
  data: SaturationCurveData[number],
  index: number,
): Highcharts.Options['series'] => {
  const actualSpendX = data.actualPoint[0]
  const lineData = data.line.map(([x, y]) => ({
    x: x,
    y: y,
    marker: {
      enabled: false,
    },
  }))

  const channelName = getChannelName(data.channel)
  const color = lineColors[index % lineColors.length]

  return [
    {
      type: 'spline',
      data: lineData,
      color: get(colorTheme, `${color.key}.${color.primaryShade}`),
      dashStyle: 'Solid',
      marker: {
        enabled: false, // Disables the markers for all points
      },
      name: channelName,
    },
    {
      type: 'scatter',
      name: `${channelName} - Actual Spend`,
      data: [[actualSpendX, data.actualPoint[1]]],
      color: get(colorTheme, `${color.key}.${color.primaryShade}`),
      marker: {
        enabled: true,
        radius: 5,
        symbol: 'circle',
      },
      zIndex: 1,
    },
    {
      type: 'scatter',
      name: `${channelName} - Proposed Spend`,
      data: [[data.optimalPoint[0], data.optimalPoint[1]]],
      color: get(colorTheme, `${color.key}.${color.secondaryShade}`),
      marker: {
        enabled: true,
        radius: 5,
        symbol: 'circle',
      },
      zIndex: 1,
    },
  ]
}

export const MultiChannelGraph: React.FC<MultiChannelGraphProps> = ({
  curveData,
}) => {
  const { currency } = useMerchantInfo()
  const gridLineOptions = getGridLineOptions(CHART_TYPE_ID.LINE)

  const chartOptions: Highcharts.Options = {
    ...baseSaturationChartOptions,
    xAxis: {
      ...baseSaturationChartOptions.xAxis,
      title: {
        text: `Marketing spend (${currency})`,
      },
      ...gridLineOptions.xAxis,
    },
    yAxis: {
      ...baseSaturationChartOptions.yAxis,
      title: {
        text: `Net gross profit 3 (${currency})`,
      },
      plotLines: yAxisZeroPlotLine,
      ...gridLineOptions.yAxis,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      formatter: getTooltipFormatter({ currency }),
    },
    series: curveData.flatMap((data, index) =>
      getLineData(data, index),
    ) as Highcharts.Options['series'],
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      containerProps={{ style: { width: '100%', minWidth: '400px' } }}
    />
  )
}
