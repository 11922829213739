import { Box, List, type HTMLChakraProps } from '@chakra-ui/react'
import {
  defaultRangeExtractor,
  useVirtualizer,
  type Range,
} from '@tanstack/react-virtual'
import { ComboBoxItem } from 'components/Dropdown/ComboBox/components/ComboBoxItem'

import { GroupHeaderItem } from 'components/Dropdown/ComboBox/components/GroupedListItems'
import { NothingFound } from 'components/Dropdown/NothingFound'
import {
  type DropdownGroup,
  type DropdownOption,
} from 'components/Dropdown/types'
import { useDimensionMetricSelectorHoveredItemSetAtom } from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import { type SetStateAction } from 'jotai'
import { useCallback, useMemo, useRef, type Dispatch } from 'react'
import { menuSizes } from 'ui/components/menu'

interface Props<TItem extends DropdownOption> {
  filteredOptions: DropdownGroup<TItem>[]
  draftSelected: string[]
  setDraftSelected: Dispatch<SetStateAction<string[] | undefined>>
}

// Inspired by tanstack sticky example: https://tanstack.com/virtual/latest/docs/framework/react/examples/sticky
export const ItemsList = <TItem extends DropdownOption>({
  filteredOptions,
  draftSelected,
  setDraftSelected,
}: Props<TItem>) => {
  const setHoveredItem = useDimensionMetricSelectorHoveredItemSetAtom()
  const activeStickyIndexRef = useRef(0)
  const listRef = useRef<HTMLUListElement | null>(null)

  const flattenedItems = useMemo(() => {
    const result: (TItem | DropdownOption)[] = []

    for (const group of filteredOptions) {
      result.push({ id: group.name, name: group.name })
      for (const item of group.items) {
        result.push(item)
      }
    }

    return result
  }, [filteredOptions])
  const stickyIndexes = useMemo(
    () =>
      filteredOptions.map((item) =>
        flattenedItems.findIndex((i) => i.id === item.name),
      ),
    [flattenedItems, filteredOptions],
  )
  const isSticky = useCallback(
    (index: number) => stickyIndexes.includes(index),
    [stickyIndexes],
  )
  const estimateSize = useCallback(() => {
    return (menuSizes.md?.item.h || 0) * 4
  }, [])

  const isActiveSticky = (index: number) =>
    activeStickyIndexRef.current === index

  const rowVirtualizer = useVirtualizer({
    count: flattenedItems.length,
    getScrollElement: () => listRef.current,
    estimateSize,
    overscan: 10,
    rangeExtractor: useCallback(
      (range: Range) => {
        const newActive =
          [...stickyIndexes]
            .reverse()
            .find((index) => range.startIndex >= index) ?? 0

        activeStickyIndexRef.current = newActive

        const next = new Set([newActive, ...defaultRangeExtractor(range)])

        return [...next].sort((a, b) => a - b)
      },
      [stickyIndexes],
    ),
  })

  return (
    // Setting h and w to 0 with minW and minH to full makes the list to be rendered covering the entire grid without exceeding the parent size
    <Box h={0} minH="full" w={0} minW="full">
      <List
        variant="menu"
        ref={listRef}
        px={4}
        pb={2}
        overflowY="auto"
        h="full"
      >
        {filteredOptions.length === 0 ? (
          <NothingFound />
        ) : (
          <Box
            style={{
              position: 'relative',
              height: `${rowVirtualizer.getTotalSize()}px`,
            }}
          >
            {rowVirtualizer
              .getVirtualItems()
              .map(({ index, start, size: virtualRowSize }) => {
                const option = flattenedItems[index]
                const optionId = option.id.toString()
                const isSelected = draftSelected.includes(optionId)
                const itemStyles: HTMLChakraProps<'li'> = {
                  ...(isSticky(index)
                    ? {
                        background: '#fff',
                        zIndex: 1,
                      }
                    : {}),
                  ...(isActiveSticky(index)
                    ? {
                        position: 'sticky',
                      }
                    : {
                        position: 'absolute',
                        transform: `translateY(${start}px)`,
                      }),
                  top: 0,
                  left: 0,
                  h: `${virtualRowSize}px`,
                  maxH: `${virtualRowSize}px`,
                }

                if (isSticky(index) || isActiveSticky(index)) {
                  const group = filteredOptions.find(
                    (group) => group.name === option.name,
                  )

                  return (
                    <GroupHeaderItem
                      key={option.id}
                      option={option}
                      itemStyles={{ ...itemStyles, pt: 2 }}
                      hasSelectDeselect={group?.showDeselect}
                      groupItems={group?.items ?? []}
                      selected={draftSelected}
                      setSelected={setDraftSelected}
                    />
                  )
                }

                const setSelected = () => {
                  const selected = isSelected
                    ? draftSelected.filter((item) => item !== optionId)
                    : [...draftSelected, optionId]

                  setDraftSelected(selected)
                }

                return (
                  <ComboBoxItem
                    key={index}
                    option={option}
                    onClick={(ev) => {
                      ev.preventDefault()
                      setSelected()
                    }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        setSelected()
                      }
                    }}
                    onMouseEnter={() => setHoveredItem(optionId)}
                    itemStyles={itemStyles}
                    isSelected={isSelected}
                    tabIndex={0}
                    role="option"
                    isOption
                  />
                )
              })}
          </Box>
        )}
      </List>
    </Box>
  )
}
