import { Flex, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import type React from 'react'

interface ErrorDataProps {
  title: string
  description?: string
}

export const ErrorData: React.FC<ErrorDataProps> = ({ title, description }) => {
  return (
    <Flex
      alignItems="flex-start"
      py={16}
      flexDir="column"
      justifyItems="flex-start"
      w="360px"
      marginX="auto"
    >
      <Image
        src="/images/ErrorData.svg"
        alt="Error fetching data"
        boxSize={200}
        mb={6}
      />
      <Typography fontSize="md" fontWeight={500} lineHeight={6} mb={2}>
        {title}
      </Typography>
      <Typography whiteSpace="normal">{description}</Typography>
    </Flex>
  )
}
