import { Dropdown } from 'components/Dropdown'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { atom, useAtom, useAtomValue } from 'jotai'
import { type FC, useCallback, useEffect } from 'react'

const FILTER_ID = {
  FRONTEND_ID: 'frontendIds',
} as const

type FilterId = (typeof FILTER_ID)[keyof typeof FILTER_ID]

type FilterStore = {
  [key in FilterId]: {
    id: FilterId
    selected: string | undefined
  }
}

const filtersAtom = atom<FilterStore>({
  [FILTER_ID.FRONTEND_ID]: {
    id: FILTER_ID.FRONTEND_ID,
    selected: undefined,
  },
})
const selectedFrontendIdAtom = atom(
  (get) => get(filtersAtom)[FILTER_ID.FRONTEND_ID].selected,
)

export const useHistoricalAnalysisFiltersAtom = () => useAtom(filtersAtom)
export const useSelectedFrontendIdAtom = () =>
  useAtomValue(selectedFrontendIdAtom)
type FilterOptionProps = {
  filter: FilterStore[keyof FilterStore]
  options: { id: string; name: string }[]
  setFilterCallback: (id: FilterId, selected: string | undefined) => void
}

const FilterOptionDropdown: FC<FilterOptionProps> = ({
  filter: { id, selected },
  options,
  setFilterCallback,
}) => (
  <Dropdown
    callback={(value) => setFilterCallback(id, value.id)}
    value={selected}
    size="sm"
    options={options}
    matchWidth={false}
    buttonProps={{ bgColor: 'white' }}
  />
)

export const Filter = () => {
  const { data: configData } = useOptimizationConfig()
  const { frontendIds = [] } =
    configData?.viewer?.merchant.optimizationConfig ?? {}

  const [filters, setFilters] = useHistoricalAnalysisFiltersAtom()
  const [trackEvent] = useTrackEvent()
  const { normalizedSites } = useMerchantInfo()

  useEffect(() => {
    const id = FILTER_ID.FRONTEND_ID

    if (!filters[id].selected && frontendIds.length > 0) {
      setFilters((state) => ({
        ...state,
        [id]: {
          ...state[id],
          selected: frontendIds[0],
        },
      }))
    }
  }, [filters, frontendIds, setFilters])

  const setFilterCallback = useCallback(
    (id: FilterId, selected: string | undefined) => {
      trackEvent({
        eventName: 'Optimizations Historical Analysis Filters Changed',
        eventProperties: { filterId: id, selectedValues: selected },
      })
      setFilters((state) => ({
        ...state,
        [id]: {
          ...state[id],
          selected,
        },
      }))
    },
    [setFilters, trackEvent],
  )

  if (frontendIds.length <= 1) return null

  return (
    <FilterOptionDropdown
      filter={filters[FILTER_ID.FRONTEND_ID]}
      setFilterCallback={setFilterCallback}
      options={frontendIds.map((id) => ({
        id,
        name: String(normalizedSites?.[id]?.name),
      }))}
    />
  )
}
