import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type ExperimentGeographyInput } from 'generated/graphql/graphql'

export const EXPERIMENT_GEOGRAPHY_QUERY = graphql(/* GraphQL */ `
  query ExperimentGeography($params: ExperimentGeographyInput!) {
    viewer {
      id
      merchant {
        id
        experimentGeography(params: $params)
      }
    }
  }
`)

export const useExperimentGeography = (params: ExperimentGeographyInput) => {
  const { data, ...query } = useQuery(EXPERIMENT_GEOGRAPHY_QUERY, {
    variables: { params },
  })

  return {
    query,
    experimentGeography: data?.viewer?.merchant.experimentGeography,
  }
}
