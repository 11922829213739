import { Box, SimpleGrid } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { type ExperimentSetupFieldsFragment } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'

interface GeographicalTargetingProps {
  setup: ExperimentSetupFieldsFragment
  experimentName: string
}

export const GeographicalTargeting: React.FC<GeographicalTargetingProps> = ({
  setup,
  experimentName,
}) => {
  const [trackEvent] = useTrackEvent()
  const handleDownload = () => {
    trackEvent({
      eventName: 'GeoLift Experiment CSV Downloaded',
      eventProperties: {
        experimentId: setup.id,
      },
    })

    const maxLength = Math.max(
      setup.treatmentGroup.length,
      setup.controlGroup.length,
    )

    const combinedData = Array.from({ length: maxLength }, (_, index) => ({
      treatment: setup.treatmentGroup[index]?.zipCode || '',
      control: setup.controlGroup[index]?.zipCode || '',
    }))
    const header = ['Treatment', 'Control']
    const rows = combinedData.map((row) => `${row.treatment},${row.control}`)
    const csvData = [header.join(','), ...rows].join('\n')
    const blob = new Blob([csvData], { type: 'text/csv' })
    const link = document.createElement('a')

    link.href = URL.createObjectURL(blob)
    link.download = `${experimentName}.csv`
    link.click()
  }

  return (
    <Box py={4} px={6} maxW="300px">
      <Typography
        fontSize="sm"
        fontWeight={500}
        lineHeight={5}
        color="gray.800"
        mb={4}
      >
        Geographical targeting
      </Typography>

      <SimpleGrid columns={2} gap={4} mb={4}>
        <Box>
          <Typography
            fontSize="xs"
            fontWeight={500}
            lineHeight={4}
            color="gray.600"
            mb={1}
          >
            Treatment
          </Typography>
          {setup.treatmentGroup.slice(0, 3).map((location) => (
            <Typography
              key={location.zipCode}
              fontSize="sm"
              fontWeight={400}
              lineHeight={5}
              color="gray.800"
            >
              {location.zipCode}
            </Typography>
          ))}
          {setup.treatmentGroup.length > 3 && (
            <>
              <Typography
                fontSize="sm"
                fontWeight={400}
                lineHeight={5}
                color="gray.800"
                mb={1}
              >
                ...
              </Typography>

              <Typography
                fontSize="xs"
                fontWeight={400}
                lineHeight={4}
                color="gray.600"
              >
                (+{setup.treatmentGroup.length - 3} more)
              </Typography>
            </>
          )}
        </Box>

        <Box>
          <Typography
            fontSize="xs"
            fontWeight={500}
            lineHeight={4}
            color="gray.600"
            mb={1}
          >
            Control
          </Typography>
          {setup.controlGroup.slice(0, 3).map((location) => (
            <Typography
              key={location.zipCode}
              fontSize="sm"
              fontWeight={400}
              lineHeight={5}
              color="gray.800"
            >
              {location.zipCode}
            </Typography>
          ))}

          {setup.controlGroup.length > 3 && (
            <>
              <Typography
                fontSize="sm"
                fontWeight={400}
                lineHeight={5}
                color="gray.800"
                mb={1}
              >
                ...
              </Typography>

              <Typography
                fontSize="xs"
                fontWeight={400}
                lineHeight={4}
                color="gray.600"
              >
                (+{setup.controlGroup.length - 3} more)
              </Typography>
            </>
          )}
        </Box>
      </SimpleGrid>

      <Button
        variant="link"
        leadingIcon={{ name: 'DownloadIcon' }}
        onClick={handleDownload}
      >
        Download CSV
      </Button>
    </Box>
  )
}
