import { useDisclosure, useToast } from '@chakra-ui/react'
import { type CreateSegmentationSetInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { set } from 'lodash-es'
import cloneDeep from 'lodash-es/cloneDeep'
import { type ReactElement } from 'react'
import { useCreateProductSet } from '../graphql/useCreateSegmentationSet'
import { SEGMENTATION_SETS_QUERY } from '../graphql/useSegmentationSets'
import { SegmentationSetPicker } from './SegmentationSetPicker'

interface AddProductSetModalProps {
  modalButton: ReactElement
  onProductSetCreated: (productSet: { name: string; id: string }) => void
}

export const AddProductSetModal: React.FC<AddProductSetModalProps> = ({
  modalButton,
  onProductSetCreated,
}) => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const [createProductSet, createProductSetMutation] = useCreateProductSet()
  const modalDisclosure = useDisclosure()

  const onApply = (productSet: CreateSegmentationSetInput) => {
    if (!productSet.name) return

    createProductSet({
      variables: {
        params: {
          name: productSet.name,
          iconColor: productSet.iconColor,
          iconName: productSet.iconName,
          dynamicDate: productSet.dynamicDate,
          labelColumnName: productSet.labelColumnName,
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery({ query: SEGMENTATION_SETS_QUERY }, (queryData) => {
          if (!queryData?.viewer?.merchant.segmentationSets) return queryData

          const queryDataClone = cloneDeep(queryData)

          set(queryDataClone, 'viewer.merchant.segmentationSets', [
            ...queryData.viewer.merchant.segmentationSets,
            data.createSegmentationSet,
          ])

          return queryDataClone
        })
      },
      onCompleted: ({ createSegmentationSet: createProductSet }) => {
        trackEvent({
          eventName: 'Segmentations Product Set Created',
          eventProperties: {
            id: createProductSet.id,
            name: createProductSet.name,
            iconName: createProductSet.iconName,
            iconColor: createProductSet.iconColor,
            labelColumnName: createProductSet.labelColumnName,
          },
        })

        modalDisclosure.onClose()
        onProductSetCreated({
          name: createProductSet.name,
          id: createProductSet.id,
        })
      },
      onError: () => {
        toast({
          description: 'Could not create product set. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <SegmentationSetPicker
      currentSegmentationSet={undefined}
      modalButton={modalButton}
      onApply={onApply}
      closeOnApply={false}
      disclosure={modalDisclosure}
      isLoading={createProductSetMutation.loading}
    />
  )
}
