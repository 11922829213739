import { Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Icon } from 'components/Icon/Icon'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { graphql } from 'generated/graphql'
import { type MappingsViewQueryQuery } from 'generated/graphql/graphql'
import { useChannelGroups } from 'graphql/useChannelGroups'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMemo } from 'react'
import { parameterTypes, sharedCellStyles } from '../shared/consts'
import { EmptyMappings } from '../shared/EmptyMappings'
import { MappingThead } from '../shared/MappingThead'
import { DeleteMappingButton } from './DeleteMappingButton'
import { ExistingMappingsCell } from './ExistingMappingsCell'

interface MappedChannel {
  utmCampaign: string
  utmMedium: string
  utmSource: string
  channelGroup: { id: string; name: string }
  channel: { id: string; name: string }
}

const columnHelper = createColumnHelper<MappedChannel>()
const getColumns = (frontendId: string | undefined = '') => [
  ...parameterTypes.map(({ id, label }) =>
    columnHelper.accessor(id, {
      cell: (info) => (
        <ExistingMappingsCell value={info.getValue()} maxWidth="150px" />
      ),
      header: label,
    }),
  ),
  columnHelper.display({
    cell: () => <Icon name="ArrowRightIcon" color="gray.500" />,
    id: 'mappingIcon',
    header: '',
  }),
  columnHelper.accessor('channelGroup', {
    cell: ({ getValue }) => (
      <ExistingMappingsCell value={getValue().name} channelValue />
    ),
    header: 'Channel group',
  }),
  columnHelper.accessor('channel', {
    cell: ({ getValue }) => (
      <ExistingMappingsCell value={getValue().name} channelValue />
    ),
    header: 'Channel',
  }),
  columnHelper.display({
    cell: (cellProps) => {
      const data = cellProps.row.original

      return (
        <DeleteMappingButton
          utmSource={data.utmSource}
          utmMedium={data.utmMedium}
          utmCampaign={data.utmCampaign}
          channelGroup={data.channelGroup.id}
          channel={data.channel.id}
          frontendId={frontendId}
        />
      )
    },
    id: 'deleteButton',
    header: '',
  }),
]

const ICON_COLUMNS = ['mappingIcon', 'deleteButton']

export const ChannelMappings_MerchantSiteFragment = graphql(/* GraphQL */ `
  fragment ChannelMappings_MerchantSite on MerchantSite {
    channelMappings {
      channel
      channelGroup
      mappings
    }
  }
`)

type Props = {
  merchantSite: NonNullable<MappingsViewQueryQuery['viewer']>['merchantSite']
  isLoading: boolean
}

export const ExistingMappings = ({ merchantSite, isLoading }: Props) => {
  const { normalizedChannelGroups } = useChannelGroups()
  const flags = useFeatureFlags()

  const mappedChannels = useMemo(
    () =>
      (merchantSite?.channelMappings?.flatMap((item) => {
        const channelGroup = normalizedChannelGroups[item.channelGroup]
        const channel =
          normalizedChannelGroups[item.channelGroup]?.channels[item.channel]

        return item.mappings.map((mapping) => ({
          utmCampaign: mapping.utm_campaign,
          utmMedium: mapping.utm_medium,
          utmSource: mapping.utm_source,
          channelGroup: { id: item.channelGroup, name: channelGroup?.name },
          channel: { id: item.channel, name: channel?.name },
        }))
      }) as unknown as MappedChannel[]) ?? [],
    [merchantSite?.channelMappings, normalizedChannelGroups],
  )

  const filteredColumns = useMemo(() => {
    const columns = getColumns(merchantSite?.frontendId)

    return flags.allowSettingsWrite
      ? columns
      : columns.filter((column) => column.id !== 'deleteButton')
  }, [flags.allowSettingsWrite, merchantSite?.frontendId])

  const table = useReactTable({
    data: mappedChannels,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  const tableRows = table.getRowModel().rows

  return (
    <>
      <Typography fontSize="sm">
        Note that created mappings will only be applied to future visits and not
        to past visits before the mapping was created.
      </Typography>
      <TableContainer>
        <Table>
          <MappingThead headerGroups={table.getHeaderGroups()} />
          <Tbody>
            {isLoading ? (
              <SkeletonTableRows columns={filteredColumns.length} rows={7} />
            ) : tableRows.length === 0 ? (
              <EmptyMappings />
            ) : (
              tableRows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      w={
                        ICON_COLUMNS.includes(cell.column.id) ? '32px' : 'unset'
                      }
                      {...sharedCellStyles}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
