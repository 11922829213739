import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type IconName } from 'components/Icon/Icon'
import { type InfoTooltipProps } from 'components/Tooltip/InfoTooltip'
import { Typography } from 'components/Typography'
import { forwardRef } from 'react'
import { ReportDetailsHeader } from './ReportDetailsHeader'

interface Props {
  title: string
  label: string
  iconName: IconName
  onClick?: () => void
  infoDetails?: InfoTooltipProps
}

// the forwardRef is needed for popups
export const ReportDetailRow = forwardRef<HTMLButtonElement, Props>(
  ({ title, label, iconName, infoDetails, onClick }, ref) => {
    return (
      <Flex direction="column" overflowX="hidden">
        <ReportDetailsHeader title={title} infoDetails={infoDetails} />

        <Flex>
          <Button
            size="sm"
            colorScheme="gray"
            variant="ghost"
            onClick={onClick}
            ref={ref}
            px={2}
            overflowX="hidden"
            textOverflow="ellipsis"
            leadingIcon={{ name: iconName }}
          >
            <Typography isTruncated>{label}</Typography>
          </Button>
        </Flex>
      </Flex>
    )
  },
)

ReportDetailRow.displayName = 'ReportDetailRow'
