import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useExperiments } from 'features/geoLift/graphql/useExperiments'
import { useState } from 'react'
import { CreateExperimentDrawer } from './components/CreateExperimentDrawer'
import {
  type ExperimentFilters,
  type ExperimentOptionFields,
  initialFilters,
} from './components/ExperimentsFilter/consts'
import { ExperimentsFilter } from './components/ExperimentsFilter/ExperimentsFilter'
import { ExperimentsTable } from './components/ExperimentsTable'

export const ExperimentsView: React.FC = () => {
  const drawerDisclosure = useDisclosure()
  const { query, experiments } = useExperiments()
  const [filters, setFilters] = useState<ExperimentFilters>(initialFilters)

  const filteredExperiments = experiments.filter((experiment) => {
    return Object.entries(filters).every(([option, values]) => {
      if (values.length === 0) return true

      return values.some(
        (value) => experiment[option as ExperimentOptionFields] === value,
      )
    })
  })

  return (
    <Box>
      <Box px={6} py={2} borderBottom="1px solid" borderColor="gray.200">
        <Typography
          color="gray.900"
          fontSize="md"
          fontWeight={500}
          lineHeight={6}
        >
          Experiments
        </Typography>
      </Box>

      <Flex px={4} py={2} justifyContent="space-between">
        <ExperimentsFilter
          experiments={experiments}
          filters={filters}
          setFilters={setFilters}
        />
        <Button onClick={drawerDisclosure.onOpen} size="sm">
          New experiment
        </Button>
        <CreateExperimentDrawer
          isOpen={drawerDisclosure.isOpen}
          onClose={drawerDisclosure.onClose}
        />
      </Flex>

      <ExperimentsTable
        experiments={filteredExperiments}
        isLoading={query.loading}
        isError={!!query.error}
      />
    </Box>
  )
}
