export const SEGMENTATIONS_ROUTE_FRAGMENTS = {
  SEGMENTATIONS: 'segmentations',
  PRODUCT_LABELS: 'product-labels',
  PRODUCT_SETS: 'product-sets',
  RULES: 'rules',
} as const

const BASE = `/${SEGMENTATIONS_ROUTE_FRAGMENTS.SEGMENTATIONS}` as const

export const SEGMENTATIONS_PAGES = {
  PRODUCT_LABELS: `${BASE}/${SEGMENTATIONS_ROUTE_FRAGMENTS.PRODUCT_LABELS}`,
  PRODUCT_SETS: `${BASE}/${SEGMENTATIONS_ROUTE_FRAGMENTS.PRODUCT_SETS}`,
  PRODUCT_RULES_BY_ID: (segmentationSetId: string) =>
    `${BASE}/${SEGMENTATIONS_ROUTE_FRAGMENTS.PRODUCT_SETS}/${segmentationSetId}/${SEGMENTATIONS_ROUTE_FRAGMENTS.RULES}` as const,
} as const
