import {
  Box,
  Flex,
  Menu,
  MenuList,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { ErrorMessage } from 'components/Error/ErrorMessage'
import { GroupHeader } from 'components/Filters/shared/GroupHeader'
import { getFormErrorMessage } from 'components/Form'
import { Label } from 'components/Label/Label'
import { Loader } from 'components/Loader/Loader'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import { MenuButton } from '../components/MenuButton'
import { MenuItem } from '../components/MenuItem'
import { MenuOverlay } from '../components/MenuOverlay'
import { NothingFound } from '../NothingFound'
import type { DropdownOption, DropdownProps } from '../types'

export const Dropdown = <T extends DropdownOption>({
  options,
  label,
  error,
  size = 'md',
  displayValue,
  value,
  isLoading = false,
  isDisabled = false,
  isReadOnly = false,
  headerLabel,
  customMenuButton,
  containerProps,
  buttonProps,
  listProps,
  menuProps,
  placeholder,
  placement,
  variant = 'outline',
  matchWidth = true,
  showOnHover,
  callback,
  onOpenChange,
  isOpen: isOpenProps,
  onClose: onCloseProps,
  onOpen: onOpenProps,
  isOpenByDefault,
  renderInPortal = true,
  menuOverlayProps,
}: DropdownProps<T>) => {
  const selectedItemRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: isOpenProps,
    onClose: onCloseProps,
    onOpen: onOpenProps,
    defaultIsOpen: isOpenByDefault,
  })
  const validationErrorMessage = getFormErrorMessage(error, label)
  const disabled = isDisabled || (options.length === 0 && !isLoading)
  const selected = useMemo(
    () => displayValue ?? options.find((option) => option.id === value)?.name,
    [displayValue, value, options],
  )

  useEffect(() => {
    onOpenChange?.(isOpen)
  }, [isOpen, onOpenChange])

  const handleMouseEnter = () => {
    if (showOnHover) {
      onOpen()
    }
  }

  const handleMouseLeave = () => {
    if (showOnHover) {
      onClose()
    }
  }

  const PortalWrapper = renderInPortal ? Portal : Fragment

  return (
    <Flex flexDir="column" {...containerProps}>
      {label && <Label isDisabled={disabled} label={label} />}
      <Menu
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        initialFocusRef={selectedItemRef}
        size={size}
        variant={variant}
        gutter={2} // offset from button
        matchWidth={matchWidth}
        preventOverflow={true}
        isLazy={true}
        placement={placement}
        {...menuProps}
      >
        <MenuButton
          buttonProps={{
            ...buttonProps,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
          }}
          variant={variant}
          error={error}
          size={size}
          isDisabled={disabled}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          isOpen={isOpen}
          customMenuButton={customMenuButton}
          buttonText={selected}
          placeholder={placeholder ?? 'Select value'}
        />
        <PortalWrapper>
          {!showOnHover && (
            <MenuOverlay isOpen={isOpen} {...menuOverlayProps} />
          )}
          <MenuList
            {...listProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Box position="sticky" top={0} zIndex="sticky" bg="white">
              {headerLabel && <GroupHeader label={headerLabel} />}
            </Box>
            {isLoading ? (
              <Flex p={3} justifyContent="center" alignItems="center">
                <Loader size="small" />
              </Flex>
            ) : options.length === 0 ? (
              <NothingFound />
            ) : (
              options.map((option) => {
                const isSelected = option.id === value

                return (
                  <MenuItem
                    key={option.id}
                    ref={isSelected ? selectedItemRef : undefined}
                    leftItem={option.leftItem}
                    rightItem={option.rightItem}
                    option={option}
                    isSelected={isSelected}
                    onClick={() => callback(option)}
                  />
                )
              })
            )}
          </MenuList>
        </PortalWrapper>
      </Menu>
      {validationErrorMessage && (
        <ErrorMessage error={validationErrorMessage} />
      )}
    </Flex>
  )
}
