import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useUpdateResults } from 'features/geoLift/graphql/useUpdateResults'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'

interface UpdateResultsButtonProps {
  experimentId: string
}

export const UpdateResultsButton: React.FC<UpdateResultsButtonProps> = ({
  experimentId,
}) => {
  const [updateResults, updateResultsState] = useUpdateResults()
  const [trackEvent] = useTrackEvent()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="sm" onClick={() => onOpen()}>
        Update results
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update results</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Calculate the experiment results using the latest data. This may
            take a few minutes to complete, but you will still be able to use
            the platform.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              isLoading={updateResultsState.loading}
              onClick={() => {
                updateResults({
                  variables: {
                    id: experimentId,
                  },
                  onCompleted: () => {
                    trackEvent({
                      eventName: 'GeoLift Experiment Results Updated',
                      eventProperties: {
                        experimentId,
                      },
                    })
                    onClose()
                  },
                })
              }}
            >
              Update results
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
