import { getCompareDatePresets } from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import { Box } from '@chakra-ui/react'
import classNames from 'classnames'
import {
  startOfDay,
  endOfDay,
  isEqual,
  isAfter,
  startOfYear,
  subYears,
  differenceInDays,
  subDays,
  addDays,
  addMonths,
  endOfMonth,
  isWithinInterval,
  startOfMonth,
} from 'date-fns'
import { type FC, useState, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { CONNECT_RANGE_CLASSNAME } from '../consts'
import { CustomDay } from '../CustomDay'
import CustomHeader from '../CustomHeader'
import { getDates, getIsPopulatedDateRange } from '../utils'
import { RangeDatepickerHeader } from './RangeDatepickerHeader/RangeDatepickerHeader'
import type { RangeDatepickerWrapperProps } from './types'

const DEFAULT_COMPARE_PRESET_ID = 'preceding-period-matching'
const START_OF_CURRENT_YEAR = startOfYear(new Date())

const getCustomCompare = (
  dateRangeStart: Date | null,
  compareDateRange: DateRange,
  compareDynamicDateRange?: string,
) => {
  if (
    compareDynamicDateRange ||
    !dateRangeStart ||
    !compareDateRange[0] ||
    !compareDateRange[1]
  ) {
    return
  }

  const numberOfDaysInCompareRange = differenceInDays(
    compareDateRange[1],
    compareDateRange[0],
  )

  // Number of days between dateRange and compareDateRange
  const daysApart = differenceInDays(dateRangeStart, compareDateRange[0])

  return { numberOfDaysInCompareRange, daysApart }
}

const getVisibleDateRange = (startDate: Date) => {
  const firstVisibleDate = startOfMonth(startDate)
  const lastVisibleDate = endOfMonth(addMonths(firstVisibleDate, 1))

  return [firstVisibleDate, lastVisibleDate]
}

export const RangeDatepickerWrapper: FC<RangeDatepickerWrapperProps> = ({
  dateRange,
  dynamicDate,
  compareDateRange,
  compareDynamicDate,
  dynamicDateOptions,
  compareDynamicDateOptions,
  setDateRange,
  setDynamicDate,
  setCompareDateRange,
  setCompareDynamicDate,
  maxDate: maxDateProp,
}) => {
  const [startDate, endDate] = dateRange
  const [selectedDate, setSelectedDate] = useState(startDate ?? new Date())
  const [visibleDateRange, setVisibleDateRange] = useState(
    getVisibleDateRange(selectedDate),
  )
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null)
  const [compareStartDate, compareEndDate] = compareDateRange
  const isCompareDateSet = getIsPopulatedDateRange(compareDateRange)
  const isDateSet = getIsPopulatedDateRange(dateRange)
  const shouldSetCompareDate = !isCompareDateSet && isDateSet

  const selectedStartDate = shouldSetCompareDate ? compareStartDate : startDate
  const selectedEndDate = shouldSetCompareDate ? compareEndDate : endDate

  const highlightedDates = useMemo(() => {
    const startHighlight = shouldSetCompareDate ? startDate : compareStartDate
    const endHighlight = shouldSetCompareDate ? endDate : compareEndDate

    if (!startHighlight || !endHighlight) return []

    const currentRange: Record<string, Date[]>[] = [
      {
        'react-datepicker__day--highlighted-range': getDates(
          startHighlight,
          endHighlight,
        ),
      },
      {
        'react-datepicker__day--highlighted-edge': [
          startHighlight,
          endHighlight,
        ],
      },
      {
        'react-datepicker__day--highlighted-range-start': [startHighlight],
      },
      {
        'react-datepicker__day--highlighted-range-end': [endHighlight],
      },
    ]

    return currentRange
  }, [
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    shouldSetCompareDate,
  ])

  const updateCompareDateRange = (range: DateRange, preset?: string) => {
    setCompareDateRange(range)
    setCompareDynamicDate?.(preset)
  }

  const updateVisibleDateRange = (startDate: Date) => {
    setVisibleDateRange(getVisibleDateRange(startDate))
  }

  const updateDateRange = (range: DateRange, preset?: string) => {
    const customCompare = getCustomCompare(
      dateRange[0],
      compareDateRange,
      compareDynamicDate,
    )

    setDateRange(range)
    setDynamicDate?.(preset)

    const [dateFrom, dateTo] = range

    if (dateFrom && dateTo) {
      if (
        !isWithinInterval(dateFrom, {
          start: visibleDateRange[0],
          end: visibleDateRange[1],
        })
      ) {
        updateVisibleDateRange(dateFrom)
        setSelectedDate(dateFrom)
      }

      // Sets the comparison to same number of days with same distance as before it was updated (only if compare range was "Custom")
      if (customCompare) {
        const updatedCompareStart = subDays(dateFrom, customCompare.daysApart)
        const updatedCompareEnd = addDays(
          updatedCompareStart,
          customCompare.numberOfDaysInCompareRange,
        )

        updateCompareDateRange([updatedCompareStart, updatedCompareEnd])

        return
      }

      const comparePresets = getCompareDatePresets(
        compareDynamicDateOptions,
        dateFrom,
        dateTo,
      )

      const { id, value } =
        comparePresets.find((preset) =>
          compareDynamicDate
            ? preset.id === compareDynamicDate
            : preset.id === DEFAULT_COMPARE_PRESET_ID,
        ) ?? {}

      if (value) {
        updateCompareDateRange(value, id)
      }
    }
  }

  const resetDateRange = () => {
    updateDateRange([null, null])
  }

  const resetEndDate = () => {
    updateDateRange([startDate, null])
  }

  const resetCompareDateRange = () => {
    updateCompareDateRange([null, null])
  }

  const resetCompareEndDate = () => {
    updateCompareDateRange([compareStartDate, null])
  }

  const onPresetClick = (id: string | undefined, range: DateRange) => {
    updateDateRange(range, id)
  }

  const onComparePresetClick = (id: string | undefined, range: DateRange) => {
    updateCompareDateRange(range, id)
  }

  const onChangeHandler = (range: DateRange) => {
    const [dateFrom, dateTo] = range
    const validDateFrom = dateFrom !== null ? startOfDay(dateFrom) : dateFrom
    const validDateTo = dateTo !== null ? endOfDay(dateTo) : dateTo
    const validRange: DateRange = [validDateFrom, validDateTo]

    if (shouldSetCompareDate) {
      updateCompareDateRange(validRange)

      return
    }

    updateDateRange(validRange)
  }

  const connectRangeClassName = (date: Date) => {
    if (!hoveredDate || !selectedStartDate) {
      return ''
    }

    return isEqual(date, selectedStartDate) &&
      isAfter(hoveredDate, selectedStartDate)
      ? CONNECT_RANGE_CLASSNAME
      : ''
  }

  const minDate = subYears(START_OF_CURRENT_YEAR, 4)
  const maxDate =
    maxDateProp !== undefined
      ? maxDateProp
      : shouldSetCompareDate && endDate
        ? endOfDay(endDate)
        : endOfDay(new Date())

  return (
    <Box>
      <RangeDatepickerHeader
        startDate={startDate}
        compareStartDate={compareStartDate}
        endDate={endDate}
        compareEndDate={compareEndDate}
        dynamicDate={dynamicDate}
        compareDynamicDate={compareDynamicDate}
        shouldSetCompareDate={shouldSetCompareDate}
        dynamicDateOptions={dynamicDateOptions}
        compareDynamicDateOptions={compareDynamicDateOptions}
        onPresetClick={onPresetClick}
        onComparePresetClick={onComparePresetClick}
        resetDateRange={resetDateRange}
        resetEndDate={resetEndDate}
        resetCompareDateRange={resetCompareDateRange}
        resetCompareEndDate={resetCompareEndDate}
      />
      <Box mt={6}>
        <Box
          className={classNames('datepicker-container', 'range-datepicker', {
            'compare-dates': shouldSetCompareDate,
          })}
        >
          <DatePicker
            key={selectedDate.getTime()} // Needed to override navigation in date picker
            monthsShown={2}
            startDate={selectedStartDate ?? undefined}
            highlightDates={highlightedDates}
            endDate={selectedEndDate ?? undefined}
            calendarStartDay={1}
            peekNextMonth={false}
            focusSelectedMonth={false}
            minDate={minDate}
            maxDate={maxDate ?? undefined}
            disabledKeyboardNavigation
            useWeekdaysShort
            selectsRange
            inline
            dayClassName={connectRangeClassName}
            onMonthChange={updateVisibleDateRange}
            onYearChange={updateVisibleDateRange}
            onChange={onChangeHandler}
            onDayMouseEnter={setHoveredDate}
            onMonthMouseLeave={() => setHoveredDate(null)}
            renderCustomHeader={(params) =>
              CustomHeader({
                ...params,
                minDate,
                maxDate,
              })
            }
            renderDayContents={CustomDay}
          />
        </Box>
      </Box>
    </Box>
  )
}
