import { getDatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { Box, Flex, Skeleton, Stack } from '@chakra-ui/react'
import { LegendRow } from 'features/optimizations/components/LegendRow/LegendRow'
import { useProductLabels } from 'features/segmentations/graphql/useProductLabels'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { type FieldArrayWithId } from 'react-hook-form'
import { colorTheme } from 'ui/theme/colors'
import { useProductLabelSegmentation } from '../../graphql/useProductLabelSegmentation'
import { PieChart } from './PieChart'
import { type RuleForm } from './types'

interface SegmentationPieChartsProps {
  selectedDatePresetId: DYNAMIC_DATE_ID
  rules: FieldArrayWithId<
    {
      rules: RuleForm[]
    },
    'rules',
    'internal_id'
  >[]
}

type MetricKey =
  | 'product:uniqueProducts'
  | 'feed:feedTotal'
  | 'order:total:dema'
  | 'product:inventoryValue'

const metrics = [
  'product:uniqueProducts',
  'feed:feedTotal',
  'order:total:dema',
  'product:inventoryValue',
] satisfies MetricKey[]

export const SegmentationPieCharts: React.FC<SegmentationPieChartsProps> = ({
  selectedDatePresetId,
  rules,
}) => {
  const selectedDatePreset = getDatePreset(selectedDatePresetId)
  const { productLabels } = useProductLabels()
  const { frontendIds = [] } = useMerchantInfo()
  const { productLabelSegmentation, loading } = useProductLabelSegmentation({
    params: {
      rules:
        rules
          ?.filter((rule) => !!rule.productLabel)
          .map((rule) => ({
            id: rule.id,
            filters: rule.filters,
            productLabelId: rule.productLabel?.id ?? '',
            productLabelName: rule.productLabel?.name ?? '',
          })) || [],
      dynamicDate: selectedDatePreset.id,
    },
  })

  const { statistics, query } = useStatistics({
    variables: {
      statisticsParams: {
        frontendIds,
        startDate: selectedDatePreset.value[0]?.toISOString() ?? '',
        endDate: selectedDatePreset.value[1]?.toISOString() ?? '',
        dimensions: ['product'],
        metrics: metrics,
        filter: {
          product: [
            {
              comparisonId: 'isNoneOf',
              value: ['Unknown', 'UNKNOWN'],
            },
          ],
        },
      },
    },
  })
  const normalizedMetrics = useNormalizedMetrics()

  if (query.loading || loading)
    return (
      <Flex
        p={4}
        shadow="base"
        bg="white"
        gap={6}
        w="max-content"
        shrink={0}
        h="max-content"
        position="sticky"
        top={4}
      >
        <Box px={6}>
          <Stack gap={4} sx={{ '*': { overflow: 'visible !important' } }}>
            <Skeleton height={180} width={180} />
            <Skeleton height={180} width={180} />
            <Skeleton height={180} width={180} />
            <Skeleton height={180} width={180} />
          </Stack>
        </Box>
        <Stack>
          <Skeleton height={18} width={'60px'} />
          <Skeleton height={18} width={'60px'} />
          <Skeleton height={18} width={'60px'} />
        </Stack>
      </Flex>
    )

  const initializeMetrics = (): Record<MetricKey, number> => ({
    'product:uniqueProducts': 0,
    'feed:feedTotal': 0,
    'order:total:dema': 0,
    'product:inventoryValue': 0,
  })

  const aggregatedProductMetrics: Record<string, Record<string, number>> = {}
  const total = initializeMetrics()
  const uncategorized = initializeMetrics()

  statistics.forEach((curr) => {
    const productId = curr.product.value
    const label = productLabelSegmentation?.[productId]?.labelId
    let targetMetrics = uncategorized

    if (label) {
      // Id we have not already initialized the metrics for this label
      if (!aggregatedProductMetrics[label]) {
        aggregatedProductMetrics[label] = initializeMetrics()
      }
      targetMetrics = aggregatedProductMetrics[label]
    }

    metrics.forEach((metric) => {
      const value = curr[metric]?.value ? Number(curr[metric].value) : 0

      targetMetrics[metric] += value
      total[metric] += value
    })
  })

  const pieCharts = [
    {
      metricKey: 'product:uniqueProducts',
      label: 'Products',
    },
    {
      metricKey: 'feed:feedTotal',
      label: 'Marketing spend',
      htmlLabel: 'Marketing <br /> spend', // We use this to display the label in the pie chart to add a line break
    },
    {
      metricKey: 'order:total:dema',
      label: 'Gross Sales',
    },
    {
      metricKey: 'product:inventoryValue',
      label: 'Inventory value',
      htmlLabel: 'Inventory <br /> value',
    },
  ] satisfies {
    metricKey: MetricKey
    label: string
    htmlLabel?: string
  }[]

  return (
    <Flex
      p={4}
      shadow="base"
      bg="white"
      gap={6}
      w="max-content"
      shrink={0}
      h="max-content"
      position="sticky"
      top={4}
    >
      <Box px={6}>
        {/* overflow visible as the charts are hacked to go outside of container size, so this targets them to make them visible */}
        <Stack gap={8} sx={{ '*': { overflow: 'visible !important' } }}>
          {pieCharts.map(({ metricKey, htmlLabel, label }) => (
            <PieChart
              key={metricKey}
              metricKey={metricKey}
              label={label}
              htmlLabel={htmlLabel}
              format={normalizedMetrics[metricKey]?.format}
              selectedDatePreset={selectedDatePreset}
              aggregatedProductMetrics={aggregatedProductMetrics}
              uncategorized={uncategorized}
              productLabels={productLabels}
            />
          ))}
        </Stack>
      </Box>
      <Box>
        {productLabels
          ?.filter((label) =>
            rules.find((rule) => rule.productLabel?.id === label.id),
          )
          .map((label) => (
            <LegendRow
              key={label.id}
              color={label.iconColor}
              label={label.name}
              type="column"
            />
          ))}

        {Object.values(uncategorized).some((value) => value > 0) && (
          <LegendRow
            color={colorTheme.gray[300]}
            label="Uncategorized"
            type="column"
          />
        )}
      </Box>
    </Flex>
  )
}
