import { Drawer, DrawerContent, DrawerOverlay, Grid } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons/ButtonIcon'
import {
  DimensionMetricSelectorDrawerOpenState,
  useDimensionMetricSelectorDrawerOpenAtom,
  useDimensionMetricSelectorHoveredItemSetAtom,
  useDraftDimensions,
  useDraftMetrics,
} from 'features/reports/atoms/dimensionMetricSelectorDrawerState'
import {
  useDimensionsState,
  useSetDimensionsState,
} from 'features/reports/hooks/useDimensionsState'
import { useFinalMetrics } from 'features/reports/hooks/useFinalMetrics'
import {
  useMetricsState,
  useSetMetricsState,
} from 'features/reports/hooks/useMetricsState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { isEqual } from 'lodash-es'
import { DimensionMetricSelectorSection } from './DimensionMetricSelectorSection'
import { ExtendedTooltipSection } from './ExtendedTooltipSection'

export const DimensionMetricSelectorDrawer = () => {
  const [openState, setOpenState] = useDimensionMetricSelectorDrawerOpenAtom()
  const setHoveredItem = useDimensionMetricSelectorHoveredItemSetAtom()
  const [draftDimensions, setDraftDimensions] = useDraftDimensions()
  const [draftMetrics, setDraftMetrics] = useDraftMetrics()
  const { dimensionKeys } = useDimensionsState()
  const { uniqueMetricKeys } = useMetricsState()
  const setMetrics = useSetMetricsState()
  const setDimensions = useSetDimensionsState()
  const [trackEvent] = useTrackEvent()
  const normalizedMetrics = useNormalizedMetrics()

  const isOpen = openState !== DimensionMetricSelectorDrawerOpenState.HIDDEN

  const { getFinalMetrics } = useFinalMetrics()

  const setSelectedMetrics = () => {
    if (draftMetrics.length === 0) {
      return
    }

    const selectedUniqueMetricsIds = [...uniqueMetricKeys].sort()
    const sortedNewMetricIds = [...draftMetrics].sort()

    // there where no changes
    if (isEqual(selectedUniqueMetricsIds, sortedNewMetricIds)) {
      return
    }

    const populatedMetrics = draftMetrics.map((id) => normalizedMetrics[id])
    const finalMetrics = getFinalMetrics(populatedMetrics)
    const finalMetricIds = finalMetrics.map((item) => item.key).sort()

    trackEvent({
      eventName: 'Report Metric Changed',
      eventProperties: {
        allMetrics: finalMetricIds,
        newMetrics: finalMetricIds.filter(
          (metric) => !selectedUniqueMetricsIds.find((m) => m === metric),
        ),
      },
    })

    setMetrics(finalMetricIds)
  }

  const setSelectedDimensions = () => {
    if (draftDimensions.length === 0) {
      return
    }

    const selectedDimensionsIds = [...dimensionKeys].sort()
    const sortedNewDimensionIds = [...draftDimensions].sort()

    // there where no changes
    if (isEqual(selectedDimensionsIds, sortedNewDimensionIds)) {
      return
    }

    trackEvent({
      eventName: 'Report Dimension Changed',
      eventProperties: {
        allDimensions: sortedNewDimensionIds,
        newDimensions: sortedNewDimensionIds.filter(
          (dimension) => !selectedDimensionsIds.find((d) => d === dimension),
        ),
      },
    })

    setDimensions(sortedNewDimensionIds)
  }

  const onClose = () => {
    setSelectedMetrics()
    setSelectedDimensions()
    setOpenState(DimensionMetricSelectorDrawerOpenState.HIDDEN)
    setHoveredItem(null)
    setDraftDimensions(undefined)
    setDraftMetrics(undefined)
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <ButtonIcon
          top={2}
          position="absolute"
          w="full"
          justifyContent="center"
          zIndex={1}
          transition="right 200ms"
          transitionTimingFunction="ease-in-out"
          left={4}
          title="Hide sidebar"
          name={isOpen ? 'ChevronDoubleRightIcon' : 'ChevronDoubleLeftIcon'}
          variant="ghost"
          colorScheme="grey"
          size="sm"
          onClick={onClose}
        />
        <Grid
          height="full"
          templateColumns={['1fr', null, '1fr 280px']}
          templateRows="1fr auto"
        >
          <DimensionMetricSelectorSection />
          <ExtendedTooltipSection />
        </Grid>
      </DrawerContent>
    </Drawer>
  )
}
