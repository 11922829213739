import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import {
  getMetricKeyWithoutAttribution,
  normalizedMetricsAtom,
} from 'graphql/statistics/useMetrics'
import { atom, type SetStateAction, useAtom, useSetAtom } from 'jotai'
import { dimensionsStateAtom } from '../hooks/useDimensionsState'
import { metricsStateAtom } from '../hooks/useMetricsState'
import {
  getActualDimensions,
  getUniqueMetrics,
  getValidMetrics,
} from '../utils/utils'

export enum DimensionMetricSelectorDrawerOpenState {
  DIMENSION = 'dimension',
  METRIC = 'metric',
  HIDDEN = 'hidden',
}

const dimensionMetricSelectorDrawerOpenAtom =
  atom<DimensionMetricSelectorDrawerOpenState>(
    DimensionMetricSelectorDrawerOpenState.HIDDEN,
  )

export const useDimensionMetricSelectorDrawerOpenAtom = () =>
  useAtom(dimensionMetricSelectorDrawerOpenAtom)
export const useDimensionMetricSelectorDrawerSetAtom = () =>
  useSetAtom(dimensionMetricSelectorDrawerOpenAtom)

const dimensionMetricSelectorHoveredItemAtom = atom<string | null>(null)

export const useDimensionMetricSelectorHoveredItemAtom = () =>
  useAtom(dimensionMetricSelectorHoveredItemAtom)
export const useDimensionMetricSelectorHoveredItemSetAtom = () =>
  useSetAtom(dimensionMetricSelectorHoveredItemAtom)

const draftDimensionsAtom = atom<string[] | undefined>(undefined)
const draftMetricsAtom = atom<string[] | undefined>(undefined)

const draftDimensions = atom(
  (get) => {
    const draftDimensions = get(draftDimensionsAtom)
    const { dimensionKeys } = get(dimensionsStateAtom)

    return draftDimensions ?? dimensionKeys
  },
  (_, set, draftDimensions: SetStateAction<string[] | undefined>) => {
    set(draftDimensionsAtom, draftDimensions)
  },
)

const draftMetrics = atom(
  (get) => {
    const draftMetrics = get(draftMetricsAtom)
    const { uniqueMetricKeys } = get(metricsStateAtom)

    const normalizedMetrics = get(normalizedMetricsAtom)
    const dimensionIds = get(draftDimensions)
    const normalizedDimensions = get(normalizedDimensionsAtom)
    const dimensions = getActualDimensions(dimensionIds, normalizedDimensions)
    const metrics = draftMetrics ?? uniqueMetricKeys

    const validMetrics = getValidMetrics(metrics, normalizedMetrics, dimensions)
    const uniqueMetrics = getUniqueMetrics(validMetrics)

    return uniqueMetrics.map(getMetricKeyWithoutAttribution)
  },
  (_, set, draftMetrics: SetStateAction<string[] | undefined>) => {
    set(draftMetricsAtom, draftMetrics)
  },
)

export const useDraftDimensions = () => useAtom(draftDimensions)
export const useDraftMetrics = () => useAtom(draftMetrics)
export const useSetDraftDimensions = () => useSetAtom(draftDimensions)
export const useSetDraftMetrics = () => useSetAtom(draftMetrics)
