import { type FlexProps } from '@chakra-ui/react'
import { type ButtonIconProps } from 'components/buttons/ButtonIcon'
import { ToggleWithIcons } from 'components/ToggleWithIcons/ToggleWithIcons'
import { ORGANIZATION_ID } from 'graphql/reports/utils'
import { type UserTeam } from 'graphql/teams/useUserTeams'
import type React from 'react'

interface TeamToggleProps {
  teams: UserTeam[]
  allTitle: string
  selected: string
  setSelected: (selected: string) => void
  buttonProps?: Omit<ButtonIconProps, 'name' | 'title'>
  containerProps?: FlexProps
}

export const TeamToggle: React.FC<TeamToggleProps> = ({
  teams,
  allTitle,
  selected,
  setSelected,
  buttonProps,
  containerProps,
}) => {
  return (
    <ToggleWithIcons
      items={[
        {
          id: ORGANIZATION_ID,
          title: allTitle,
          iconName: 'BulletListIcon',
        },
        ...teams.map((team) => ({
          iconName: team.iconName,
          id: team.id,
          title: team.name,
          iconProps: {
            hexColor: team.iconColor,
            size: 'small' as const,
          },
        })),
      ]}
      buttonProps={buttonProps}
      selected={selected}
      setSelected={setSelected}
      {...containerProps}
    />
  )
}
