import {
  Badge as ChakraBadge,
  type BadgeProps as ChakraBadgeProps,
} from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import { forwardRef } from 'react'

interface BadgeProps extends ChakraBadgeProps {
  icon?: IconName
}

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ children, colorScheme, icon, size = 'sm', ...rest }, ref) => {
    if (!children) return null

    return (
      <ChakraBadge ref={ref} colorScheme={colorScheme} size={size} {...rest}>
        {icon && <Icon name={icon} size="small" mr={1} />}
        {children}
      </ChakraBadge>
    )
  },
)

Badge.displayName = 'Badge'

export default Badge
