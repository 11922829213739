import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_PRODUCT_SET_MUTATION = graphql(/* GraphQL */ `
  mutation CreateSegmentationSet($params: CreateSegmentationSetInput!) {
    createSegmentationSet(params: $params) {
      ...SegmentationSetFields
    }
  }
`)

export const useCreateProductSet = () => {
  return useMutation(CREATE_PRODUCT_SET_MUTATION)
}
