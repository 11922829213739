import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useUpdateActiveSetup } from 'features/geoLift/graphql/useUpdateActiveSetup'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'

interface FinalizeExperimentButtonProps {
  experimentId: string
  selectedSetupId: string
}

export const FinalizeExperimentButton: React.FC<
  FinalizeExperimentButtonProps
> = ({ experimentId, selectedSetupId }) => {
  const [updateActiveSetup, updateActiveSetupState] = useUpdateActiveSetup()
  const [trackEvent] = useTrackEvent()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size="sm" onClick={() => onOpen()}>
        Finalize setup
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Finalize setup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            When the setup is finalized it will no longer be possible to change
            any of the experiment parameters.
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              isLoading={updateActiveSetupState.loading}
              onClick={() => {
                updateActiveSetup({
                  variables: {
                    params: {
                      id: experimentId,
                      activeExperimentSetupId: selectedSetupId,
                    },
                  },
                  onCompleted: () => {
                    trackEvent({
                      eventName: 'GeoLift Experiment Setup Finalized',
                      eventProperties: {
                        experimentId,
                      },
                    })
                    onClose()
                  },
                })
              }}
            >
              Finalize setup
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
