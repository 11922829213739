import { Box, FormLabel } from '@chakra-ui/react'

import { Input } from 'components/Input'
import { type UserTeam } from 'graphql/teams/useUserTeams'
import { startTransition, useRef } from 'react'
import { TeamToggle } from '../TeamToggle'

interface Props {
  teams: UserTeam[]
  selectedTeam: string
  onTeamChange: (team: string) => void
  inputValue: string
  setInputValue: (value: string) => void
  shouldShowTeamToggle: boolean
  placeholder?: string
}
export const SearchInput = ({
  teams,
  selectedTeam,
  onTeamChange,
  inputValue,
  setInputValue,
  shouldShowTeamToggle,
  placeholder,
}: Props) => {
  const teamToggleRef = useRef<HTMLDivElement>(null)

  return (
    <FormLabel m={0}>
      <Input
        size="lg"
        variant="flushed"
        leadingIcon={{ name: 'MagnifyingGlassIcon', size: 'small' }}
        type="text"
        placeholder={placeholder}
        action={
          shouldShowTeamToggle ? (
            <Box px={2} ref={teamToggleRef}>
              <TeamToggle
                teams={teams}
                allTitle="All dimensions"
                selected={selectedTeam}
                setSelected={onTeamChange}
                buttonProps={{ size: 'sm' }}
                containerProps={{ flexWrap: 'nowrap' }}
              />
            </Box>
          ) : undefined
        }
        actionWidth={teamToggleRef.current?.clientWidth}
        value={inputValue}
        onChange={(e) => startTransition(() => setInputValue(e.target.value))}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus // autoFocus guarantees that the input will be focused when the component is mounted even with isOpenByDefault
      />
    </FormLabel>
  )
}
