import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  type TextProps,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { getCompareDateString } from 'components/Datepicker/RangeDatepicker/getCompareDateString/getCompareDateString'
import { Icon } from 'components/Icon/Icon'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { type ExperimentSummary } from 'features/geoLift/graphql/fragments'
import { GEO_LIFT_PAGES } from 'features/geoLift/routePages'
import { StatusTag } from 'features/geoLift/shared/StatusTag'
import { stringIdToLabel } from 'features/geoLift/utils'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useChannels } from 'graphql/useChannels'
import { useCountries } from 'graphql/useCountries'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { ErrorData } from 'shared/ErrorData/ErrorData'
import { ExperimentContextMenu } from './ExperimentContextMenu/ExperimentContextMenu'

const columnHelper = createColumnHelper<ExperimentSummary>()

const ColumnText = ({
  children,
  ...rest
}: { children: React.ReactNode } & TextProps) => {
  return (
    <Typography fontSize="xs" color="gray.800" lineHeight={4} {...rest}>
      {children}
    </Typography>
  )
}

interface Props {
  experiments: ExperimentSummary[]
  isLoading: boolean
  isError: boolean
}

export const ExperimentsTable: React.FC<Props> = ({
  experiments,
  isLoading,
  isError,
}) => {
  const columns = useColumns()
  const table = useReactTable({
    data: experiments,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const tableRows = table.getRowModel().rows

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted()

                  return (
                    <Th
                      key={header.id}
                      bg="gray.50"
                      minW="100px"
                      px={2}
                      _first={{ pl: 4, borderLeft: 'none' }}
                      fontSize="xs"
                      color="gray.800"
                      fontWeight={500}
                      border="solid 1px"
                      borderColor="gray.200"
                      onClick={header.column.getToggleSortingHandler()}
                      cursor="pointer"
                    >
                      <Flex alignItems="center" gap={0.5}>
                        {flexRender(
                          header.column.columnDef.header as string,
                          header.getContext(),
                        )}
                        <Icon
                          visibility={isSorted ? 'visible' : 'hidden'}
                          name={
                            isSorted === 'asc' ? 'ArrowUpIcon' : 'ArrowDownIcon'
                          }
                          size="small"
                        />
                      </Flex>
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoading && tableRows.length === 0 ? (
              <SkeletonTableRows columns={columns.length} rows={6} />
            ) : isError ? (
              <Tr>
                <Td colSpan={columns.length} border="unset">
                  <ErrorData
                    title="Unable to fetch experiments"
                    description="An error has occurred and we are working to fix the problem! Please try again in a while or reach out to us."
                  />
                </Td>
              </Tr>
            ) : tableRows.length === 0 ? (
              <Tr>
                <Td colSpan={columns.length} border="unset">
                  <EmptyData label="No experiments available." />
                </Td>
              </Tr>
            ) : (
              tableRows.map((row) => (
                <Tr
                  key={row.id}
                  as={Link}
                  to={GEO_LIFT_PAGES.EXPERIMENT_BY_ID(row.original.id)}
                  display="table-row" // mimic a table row
                  verticalAlign="inherit"
                  _hover={{
                    bg: 'gray.50',
                    cursor: 'pointer',
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      px={2}
                      _first={{ pl: 4, borderLeft: 'none' }}
                      border="solid 1px"
                      borderColor="gray.200"
                      h={12}
                      position="relative"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const useColumns = () => {
  const { normalizedSites } = useMerchantInfo()
  const normalizedMetrics = useNormalizedMetrics()
  const { normalizedChannels } = useChannels()
  const { normalizedCountries } = useCountries()

  return useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ({ getValue }) => {
          return <ColumnText>{getValue()}</ColumnText>
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ getValue }) => {
          const status = getValue()

          return (
            <>
              <StatusTag status={status} size="small" />
              {status === 'configuring' && (
                <Box position="absolute" w="full" left={0} bottom={0}>
                  <ProgressBar progress={null} size="small" />
                </Box>
              )}
            </>
          )
        },
      }),
      columnHelper.accessor('startDate', {
        header: 'Test period',
        cell: ({ row }) => {
          return (
            <ColumnText>
              {getCompareDateString(
                new Date(row.original.startDate),
                new Date(row.original.endDate),
              )}
            </ColumnText>
          )
        },
      }),
      columnHelper.accessor('frontendId', {
        header: 'Storefront',
        cell: ({ getValue }) => {
          const storefrontName = normalizedSites[getValue()].name ?? getValue()

          return <ColumnText>{storefrontName}</ColumnText>
        },
        sortingFn: (a, b) => {
          return (
            normalizedSites[a.original.frontendId].name.localeCompare(
              normalizedSites[b.original.frontendId].name,
            ) ?? 0
          )
        },
      }),
      columnHelper.accessor('country', {
        header: 'Country',
        cell: ({ getValue }) => {
          const countryCode = getValue()

          return (
            <ColumnText>
              {normalizedCountries[countryCode].name ?? countryCode}
            </ColumnText>
          )
        },
        sortingFn: (a, b) => {
          return normalizedCountries[a.original.country].name.localeCompare(
            normalizedCountries[b.original.country].name,
          )
        },
      }),
      columnHelper.accessor('channel', {
        header: 'Channel',
        cell: ({ getValue }) => {
          return <ColumnText>{normalizedChannels[getValue()].name}</ColumnText>
        },
        sortingFn: (a, b) => {
          return normalizedChannels[a.original.channel].name.localeCompare(
            normalizedChannels[b.original.channel].name,
          )
        },
      }),
      columnHelper.accessor('purpose', {
        header: 'Type',
        cell: ({ getValue }) => {
          const purpose = getValue()

          return <ColumnText>{stringIdToLabel(purpose)}</ColumnText>
        },
      }),
      columnHelper.accessor('targetVariable', {
        header: 'Target KPI',
        cell: ({ getValue }) => {
          return (
            <ColumnText>
              {normalizedMetrics[getValue()]?.label ?? getValue()}
            </ColumnText>
          )
        },
        sortingFn: (a, b) => {
          return normalizedMetrics[
            a.original.targetVariable
          ]?.label.localeCompare(
            normalizedMetrics[b.original.targetVariable]?.label,
          )
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        cell: ({ row }) => {
          return (
            <Box
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              <ExperimentContextMenu experiment={row.original} />
            </Box>
          )
        },
      }),
    ]
  }, [
    normalizedMetrics,
    normalizedSites,
    normalizedChannels,
    normalizedCountries,
  ])
}
