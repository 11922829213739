import { Box, Portal } from '@chakra-ui/react'
import {
  type FC,
  type PropsWithChildren,
  type MouseEvent,
  Fragment,
} from 'react'

export interface MenuOverlayProps {
  isOpen: boolean
  onClick?: (event: MouseEvent) => void
  renderInPortal?: boolean
}

export const MenuOverlay: FC<PropsWithChildren<MenuOverlayProps>> = ({
  isOpen,
  onClick,
  renderInPortal = false,
}) => {
  if (!isOpen) return null

  const Container = renderInPortal ? Portal : Fragment

  return (
    <Container>
      <Box
        position="fixed"
        zIndex={1}
        left={0}
        top={0}
        w="100vw"
        h="100vh"
        onClick={(e) => {
          e.stopPropagation()
          onClick?.(e)
        }}
      />
    </Container>
  )
}
