import { graphql } from 'generated/graphql'
import {
  type ExperimentConfigFieldsFragment,
  type ExperimentFieldsFragment,
  type ExperimentSummaryFieldsFragment,
} from 'generated/graphql/graphql'

export type ExperimentStatus =
  | 'configuring'
  | 'draft'
  | 'upcoming'
  | 'running'
  | 'completed'
  | 'finalized'
  | 'failed'

export const ExperimentSummaryFragment = graphql(/* GraphQL */ `
  fragment ExperimentSummaryFields on ExperimentSummary {
    id
    name
    purpose
    frontendId
    status
    startDate
    endDate
    channel
    country
    targetVariable
    __typename
  }
`)

export type ExperimentSummary = Omit<
  ExperimentSummaryFieldsFragment,
  'status'
> & {
  status: ExperimentStatus
}

export const ExperimentFragment = graphql(/* GraphQL */ `
  fragment ExperimentFields on Experiment {
    id
    status
    config {
      ...ExperimentConfigFields
    }
    setup {
      ...ExperimentSetupFields
    }
    suggestions {
      ...ExperimentSetupFields
    }
    inference {
      ...ExperimentInferenceFields
    }
    __typename
  }
`)

export type Experiment = Omit<ExperimentFieldsFragment, 'config' | 'status'> & {
  config: ExperimentConfig
  status: ExperimentStatus
}

export const ExperimentConfigFragment = graphql(/* GraphQL */ `
  fragment ExperimentConfigFields on ExperimentConfig {
    id
    purpose
    name
    frontendId
    country
    zoneType
    zipCodeLength
    channel
    funnelCampaigns
    statusInference
    statusSuggestions
    targetVariable
    targetReturnOnSpend
    startDate
    endDate
    treatmentPeriod
    postTreatmentPeriod
    __typename
  }
`)

export enum ExperimentConfigStatus {
  RUNNING = 'running',
  FAILED = 'failed',
}

export enum ExperimentConfigPurpose {
  LIFT = 'lift',
  NEW_CHANNEL = 'new_channel',
}

export type ExperimentConfig = Omit<
  ExperimentConfigFieldsFragment,
  'purpose'
> & {
  purpose: ExperimentConfigPurpose
}

export const ExperimentSetupFragment = graphql(/* GraphQL */ `
  fragment ExperimentSetupFields on ExperimentSetup {
    id
    correlation
    isValidExperiment
    treatmentProportion
    avgMinimumDetectableEffect
    absLiftInZero
    treatmentGroup {
      ...LocationFields
    }
    controlGroup {
      ...LocationFields
    }
    channelMetrics {
      estimatedLift
      estimatedMaxLiftGivenPastSpend
      estimatedTotalSpend
      estimatedTotalSpendPastPeriod
      estimatedTotalTarget
      estimatedTotalTargetIncremental
      isValidChannelSpend
      __typename
    }
    __typename
  }
`)

export const LocationFragment = graphql(/* GraphQL */ `
  fragment LocationFields on Location {
    commutingZone
    weight
    zipCode
    __typename
  }
`)

export const ExperimentInferenceFragment = graphql(/* GraphQL */ `
  fragment ExperimentInferenceFields on ExperimentInference {
    id
    syntheticControlGroup {
      ...LocationFields
    }
    controlObservations {
      ...InferenceGraphPointFields
    }
    treatmentObservations {
      ...InferenceGraphPointFields
    }
    summary {
      ...InferenceSummaryFields
    }
    cumulativeLift {
      ...InferenceGraphPointFields
    }
    __typename
  }
`)

export const InferenceGraphPointFragment = graphql(/* GraphQL */ `
  fragment InferenceGraphPointFields on InferenceGraphPoint {
    date
    lowerBound
    upperBound
    value
  }
`)

export const InferenceSummaryFragment = graphql(/* GraphQL */ `
  fragment InferenceSummaryFields on InferenceSummary {
    totalTarget
    totalTargetIncremental
    avgAtt
    incrementalFactor
    lift
    pValue
    totalSpend
    totalTarget
    totalTargetIncremental
    __typename
  }
`)
